<template>
  <b-card class="scroll-card">
    <b-overlay :show="isBusy" no-wrap opacity="0.4" rounded="lg">
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="dark" />
          <b-spinner type="grow" variant="primary" />
          <b-spinner small type="grow" variant="dark" />
          <!-- We add an SR only text for screen readers -->
          <span class="sr-only">Chargement wait...</span>
        </div>
      </template>
    </b-overlay>

    <vue-perfect-scrollbar
      ref="card-scroll"
      :settings="perfectScrollbarSettings"
    >
      <div v-if="!isBusy">
        <b-alert
          variant="primary"
          :show="!programs || programs.length === 0"
          class="mx-2"
        >
          <h4 class="alert-heading">
            Aucun programme disponible dans la zone de recherche.
          </h4>
        </b-alert>
      </div>

      <div v-if="!!programs && programs.length > 0">
        <div
          v-for="program in programs"
          :key="program.id"
          @click="selectProgram(program)"
        >
          <program-card
            :program="program"
            :filters="filters"
            class="cursor-pointer item-program mr-1"
            v-b-toggle.sidebar-program
          />
        </div>
      </div>
    </vue-perfect-scrollbar>
  </b-card>
</template>

<script>
import { BCard, BOverlay, BSpinner, BAlert, VBToggle } from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ProgramCard from "../shared/ProgramCard";

export default {
  components: {
    BCard,
    BOverlay,
    BSpinner,
    BAlert,

    // 3rd party
    VuePerfectScrollbar,
    ProgramCard,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
    programs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
      },
    };
  },
  mounted() {
    const tableScrollBody = this.$refs["card-scroll"].$el;
    /* Consider debouncing the event call */
    tableScrollBody.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    /* Clean up just to be sure */
    const tableScrollBody = this.$refs["card-scroll"].$el;
    tableScrollBody.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    selectProgram(program) {
      this.filters.selectedProgram = program;
    },
    onScroll(event) {
      if (
        event.target.scrollTop + event.target.clientHeight >=
        event.target.scrollHeight
      ) {
        if (!this.isBusy) {
          this.$emit("scrollBottomUpdated", this.page + 1);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/variables";

.card-body {
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.scroll-card {
  height: 84%;
}

.ps-container {
  max-height: 97%;
  position: absolute;
  width: 100%;
}

.item-program {
  box-shadow: 0 4px 25px 0 rgba($black, 0.1) !important;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 25px 0 rgba($black, 0.2) !important;
  }
}
</style>
