import { render, staticRenderFns } from "./ProgramCardShort.vue?vue&type=template&id=fb8bf5e6&"
import script from "./ProgramCardShort.vue?vue&type=script&lang=js&"
export * from "./ProgramCardShort.vue?vue&type=script&lang=js&"
import style0 from "./ProgramCardShort.vue?vue&type=style&index=0&id=fb8bf5e6&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports