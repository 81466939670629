<template>
  <div class="h-100">
    <!-- ECommerce Header -->
    <section id="itemgrid-header">
      <div class="row mb-1">
        <div class="col-sm-12">
          <div class="itemgrid-header-items mt-50">
            <div class="result-toggler">
              <feather-icon
                icon="SearchIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">
                {{ totalPrograms.count }} Programmes
              </div>
            </div>
            <div class="view-options d-flex">
              <comparator-dropdown />
              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon :icon="option.icon" size="18" />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <searcher-map
      v-if="!itemView"
      :filters="filters"
      :filterOptions="filterOptions"
      :totalPrograms="totalPrograms"
      :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
    ></searcher-map>
    <searcher-list
      v-else
      :filters="filters"
      :filterOptions="filterOptions"
      :totalPrograms="totalPrograms"
      :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
    ></searcher-list>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import { BFormRadioGroup, BFormRadio } from "bootstrap-vue";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";

// Leaflet
import { latLng } from "leaflet";

// Internal Vue Components
import { useDecimmoUi, useDecimmoFiltersAndResult } from "./useDecimmoSearcher";
import decimmoStoreModule from "../decimmoStoreModule";
import SearcherMap from "./map-searcher/SearcherMap.vue";
import SearcherList from "./list-searcher/SearcherList.vue";
import ComparatorDropdown from "@/views/decimmo/comparator/ComparatorDropdown.vue";

export default {
  components: {
    BFormRadioGroup,
    BFormRadio,

    // Internal Components
    SearcherMap,
    SearcherList,
    ComparatorDropdown,
  },
  directives: {
    Ripple,
  },
  setup() {
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

    const { itemView, itemViewOptions, totalPrograms } = useDecimmoUi();

    const { filters, filterOptions } = useDecimmoFiltersAndResult();

    const DECIMMO_APP_STORE_MODULE_NAME = "app-decimmo";

    // Register module
    if (!store.hasModule(DECIMMO_APP_STORE_MODULE_NAME))
      store.registerModule(DECIMMO_APP_STORE_MODULE_NAME, decimmoStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DECIMMO_APP_STORE_MODULE_NAME))
        store.unregisterModule(DECIMMO_APP_STORE_MODULE_NAME);
    });

    return {
      filters,
      filterOptions,
      itemView,
      itemViewOptions,
      totalPrograms,

      mqShallShowLeftSidebar,
    };
  },
  mounted() {
    // Fetch user navigator position
    navigator.geolocation.getCurrentPosition((position) => {
      this.filters.center = latLng(
        position.coords.latitude,
        position.coords.longitude
      );
      this.filters.userMarker = latLng(
        position.coords.latitude,
        position.coords.longitude
      );
    });
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-itemgrid.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
