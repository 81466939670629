<template>
  <div class="content-right-match-height">
    <!-- SideBar Right -->
    <program-sidebar :program="filters.selectedProgram" />

    <!-- Map -->
    <map-card :filters="filters" :programs="programs" class="h-100" />

    <!-- Sidebar Left -->
    <portal to="content-renderer-sidebar-detached-left">
      <div class="sidebar-detached sidebar-left">
        <div class="sidebar">
          <div class="sidebar-shop" :class="{ show: mqShallShowLeftSidebar }">
            <select-card
              :filters="filters"
              :filter-options="filterOptions"
              :isGeoBusy="isGeoBusy"
            />
            <programs-scroll
              @scrollBottomUpdated="scrollBottomUpdated"
              :filters="filters"
              :programs="paginatedPrograms"
              :page="filters.page"
              :isBusy="isBusy"
            />
          </div>
        </div>

        <div
          class="body-content-overlay"
          :class="{ show: mqShallShowLeftSidebar }"
          @click="$emit('update:mq-shall-show-left-sidebar', false)"
        />
      </div>
    </portal>
  </div>
</template>

<script>
import _ from "lodash";
import Ripple from "vue-ripple-directive";

// Internal Vue Components
import { useDecimmoAPI } from "../useDecimmoSearcher";
import MapCard from "./MapCard.vue";
import SelectCard from "./SelectCard.vue";
import ProgramsScroll from "./ProgramsScroll.vue";
import ProgramSidebar from "../shared/ProgramSidebar.vue";

export default {
  components: {
    // Internal Components
    MapCard,
    SelectCard,
    ProgramsScroll,
    ProgramSidebar,
  },
  directives: {
    Ripple,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    totalPrograms: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    "filters.bounds"(val, oldVal) {
      // if map slided, ask for refresh search
      if (val !== oldVal) {
        this.debouncedFetchedProgramsFiltered();
      }
    },
    "filters.userMarker"(val) {
      // if userMarker change, sync geocoding address
      this.isGeoBusy = true;
      this.fetchAddressByLatlng(val)
        .then((response) => {
          this.isGeoBusy = false;
          this.filters.address = response.data.features[0].properties.label;
          this.filters.zipCode =
            response.data.features[0].properties.postcode.toString();
        })
        .catch(() => {
          this.isGeoBusy = false;
        });
    },
  },
  setup(props) {
    const {
      fetchAddressByLatlng,
      findPrograms,
      findProgramsMap,
      programs,
      paginatedPrograms,
      pagination,
      isBusy,
      isGeoBusy,
    } = useDecimmoAPI();

    const fetchProgramsMap = (filters) => {
      // empty programs scroll array
      paginatedPrograms.value.splice(0, paginatedPrograms.value.length);
      // fetch programs map array
      isBusy.value = true;
      findProgramsMap(filters)
        .then((response) => {
          if (response.data.programs !== null) {
            props.totalPrograms.count = response.data.pagination.total;
            programs.value = response.data.programs;
          } else {
            props.totalPrograms.count = 0;
            programs.value.splice(0, programs.value.length);
          }
          isBusy.value = false;
        })
        .catch(() => {
          isBusy.value = false;
        });
    };

    const fetchProgramsGrid = (filters) => {
      // fetch programs scroll array
      isBusy.value = true;
      findPrograms(filters)
        .then((httpResp) => {
          if (
            httpResp.data.programs !== null &&
            paginatedPrograms.value.length ===
              (filters.page - 1) * filters.perPage
          ) {
            paginatedPrograms.value = paginatedPrograms.value.concat(
              httpResp.data.programs
            );
          }
          isBusy.value = false;
        })
        .catch(() => {
          isBusy.value = false;
        });
    };

    return {
      isBusy,
      isGeoBusy,
      programs,
      paginatedPrograms,
      pagination,

      fetchAddressByLatlng,
      fetchProgramsMap,
      fetchProgramsGrid,
    };
  },
  created() {
    // tempo multi same refresh call
    this.debouncedFetchedProgramsFiltered = _.debounce(
      this.fetchProgramsFiltered,
      800 // wait for geoloc / map init
    );

    // Reinit page
    this.filters.page = 1;
    this.debouncedFetchedProgramsFiltered();
  },
  methods: {
    scrollBottomUpdated(page) {
      this.filters.page = page;
      this.fetchProgramsGrid(this.filters);
    },
    fetchProgramsFiltered() {
      this.filters.page = 1;
      this.fetchProgramsMap(this.filters);
      this.fetchProgramsGrid(this.filters);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  margin-bottom: 0.5rem !important;
}

.content-right-match-height {
  height: 92%;
}
</style>
