<template>
  <b-row class="popup-position-container">
    <b-col sm="12">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="success"
        class="popup-button"
        @click="askPositionReport()"
      >
        Etudier l'emplacement
        <feather-icon type="submit" icon="FileTextIcon" class="ml-50" />
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useDecimmoReport } from "./../../useDecimmoReport";

export default {
  components: {
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { fetchReportPositionData } = useDecimmoReport();

    return {
      fetchReportPositionData,
    };
  },
  methods: {
    askPositionReport() {
      this.$swal({
        title: this.filters.address,
        text: "Voulez-vous lancer une étude à l'emplacement sans programme ?",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-secondary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.fetchReportPositionData(
            this.filters.userMarker.lat,
            this.filters.userMarker.lng,
            this.filters.zipCode
          );
        }
      });
    },
  },
};
</script>

<style>
.popup-position-container {
  width: 20rem !important;
}

.popup-button {
  width: 100%;
}
</style>
