<template>
  <div class="comparator-container">
    <b-dropdown
      id="dropdown-comparator"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      right
      :variant="classButton"
      class="dropdown-icon-wrapper"
      menu-class="dropdown-comparator-menu"
    >
      <template #button-content>
        <feather-icon icon="CompassIcon" size="13" class="mr-50" />
        <span class="mr-1">Comparateur</span>
        <span v-if="programs && programs.length >= 1"
          >({{ programs.length }})</span
        >
      </template>
      <h5 v-if="programs && programs.length === 0" class="text-center my-1">
        Aucun programme sélectionné
      </h5>
      <b-card
        v-for="program in programs"
        :key="program.id"
        no-body
        class="program-comparator-container mx-1 my-1"
      >
        <program-card-short :program="program" />
      </b-card>
      <h6 v-if="programs && programs.length === 1" class="ml-1">
        Sélectionnez un 2ème programme
      </h6>
      <b-row align-h="between">
        <b-col xs cols="5">
          <b-button
            v-if="programs && programs.length >= 2"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="warning"
            class="ml-1"
            @click="
              $store.commit('app-comparator/RESET_PROGRAMS_FROM_COMPARATOR')
            "
          >
            Vider la liste
            <feather-icon icon="RotateCwIcon" />
          </b-button>
        </b-col>
        <b-col xs cols="5" class="text-right">
          <b-button
            v-if="programs && programs.length >= 2"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="mr-1"
            :to="{ name: 'decimmo-comparator' }"
          >
            Comparer
            <feather-icon icon="EyeIcon" />
          </b-button>
        </b-col>
      </b-row>
    </b-dropdown>
  </div>
</template>

<script>
import { BRow, BCol, BDropdown, BCard, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

// Module Comparator API Client
import store from "@/store";
import comparatorStoreModule from "../comparatorStoreModule";

import ProgramCardShort from "./components/ProgramCardShort.vue";

export default {
  components: {
    BRow,
    BCol,
    BDropdown,
    BCard,
    BButton,

    ProgramCardShort,
  },
  directives: {
    Ripple,
  },
  watch: {
    programs(val) {
      if (this.$route.name === "decimmo-comparator" && val && val.length < 2) {
        this.$router.push({ name: "decimmo-searcher" });
        this.$swal({
          title: "Sélectionnez au moins deux programmes",
          icon: "info",
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },
  },
  setup() {
    const COMPARATOR_APP_STORE_MODULE_NAME = "app-comparator";

    // Register module
    if (!store.hasModule(COMPARATOR_APP_STORE_MODULE_NAME))
      store.registerModule(
        COMPARATOR_APP_STORE_MODULE_NAME,
        comparatorStoreModule
      );
  },
  computed: {
    classButton() {
      if (this.$store.getters["app-comparator/getPrograms"].length > 0)
        return "primary";
      return "outline-primary";
    },
    programs() {
      return this.$store.getters["app-comparator/getPrograms"];
    },
  },
};
</script>

<style lang="scss">
.program-comparator-container {
  width: 30rem;
  height: 6rem;
}

.dropdown-comparator-menu {
  width: 32rem !important;
  /* box-shadow: 0 4px 24px 0 rgb(34 41 47 / 40%) !important; */
  @media (min-width: 767.98px) {
    width: 32rem !important;
  }
}
</style>
