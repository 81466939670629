<template>
  <b-card v-if="program && program.name">
    <b-overlay :show="reportData.isBusy" no-wrap opacity="0.4" rounded="lg">
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="dark" />
          <b-spinner type="grow" variant="primary" />
          <b-spinner small type="grow" variant="dark" />
          <!-- We add an SR only text for screen readers -->
          <span class="sr-only">Chargement wait...</span>
        </div>
      </template>
    </b-overlay>

    <div class="d-flex align-items-center justify-content-center">
      <b-img
        :src="program.brochure_url"
        :alt="`Image of ${program.name}`"
        class="program-img"
        fluid
      />
    </div>

    <div>
      <b-card-text>
        <b-row>
          <b-col cols="12" class="text-center">
            <b-button
              v-if="!inComparator"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click="addProgramToComparator"
              :disabled="isFull"
            >
              Comparer
              <feather-icon icon="CompassIcon" size="15" />
            </b-button>
            <b-button
              v-if="inComparator"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="secondary"
              class="mt-1 mr-1"
              @click="removeProgramFromComparator"
            >
              Retirer
              <feather-icon icon="CompassIcon" size="15" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="success"
              class="mt-1"
              @click="fetchReportData(program.id)"
            >
              Consulter l'analyse
              <feather-icon icon="FileTextIcon" size="15" />
            </b-button>
          </b-col>
          <b-col sm="12">
            <b-card-text>
              <h3 class="text-primary mt-2">
                {{ program.name }}
              </h3>
              <span>proposé par</span>
              <b-badge variant="light-info">
                <span>
                  {{ program.promoter_name }}
                </span>
              </b-badge>
            </b-card-text>

            <hr class="my-50" />

            <div class="d-flex flex-wrap mt-1">
              <h4 class="mr-1">
                {{
                  currencyPriceMeters(
                    program.price.full_vat_price_per_meter_parking_excepted
                  )
                }}
              </h4>
              <ul class="list-inline pl-1 border-left">
                Prix moyen sur l'ensemble des lots. (Parking exclus)
              </ul>
            </div>

            <div
              class="d-flex flex-wrap mt-50"
              v-if="program.price.full_vat_price_per_meter_parking_included"
            >
              <h4 class="mr-1">
                {{
                  currencyPriceMeters(
                    program.price.full_vat_price_per_meter_parking_included
                  )
                }}
              </h4>
              <ul class="list-inline pl-1 border-left">
                Prix moyen sur l'ensemble des lots. (Parking inclus)
              </ul>
            </div>

            <hr class="my-50" />

            <h6>{{ program.location.sector_name }}</h6>
            <h6>
              {{ program.location.street }} - {{ program.location.zip }} -
              {{ program.location.city }}
            </h6>

            <hr class="my-50" />

            <b-row>
              <b-col cols="12" class="mb-1">
                <b-badge
                  v-for="fundingType in fundingTypeArray"
                  :key="fundingType.code"
                  :variant="fundingType.variant"
                  class="mr-50 mb-50"
                  v-b-tooltip.hover.v-primary
                  :title="fundingType.description"
                >
                  {{ fundingType.value }}
                </b-badge>
                <b-badge
                  v-if="!!program.reduced_vat_zone"
                  variant="info"
                  v-b-tooltip.hover.v-primary
                  title="TVA réduite"
                >
                  <span>&#8600;</span>TVA
                </b-badge>
              </b-col>
              <b-col cols="6">
                <ul class="itemgrid-features list-unstyled">
                  <li>
                    <feather-icon icon="SunriseIcon" />
                    <span
                      >Livraison:
                      {{ formatReverseDate(program.delivery_date) }}</span
                    >
                  </li>
                  <li>
                    <feather-icon icon="ShoppingBagIcon" />
                    <span
                      >Commercialisation:
                      {{ formatReverseDate(program.sale_date) }}</span
                    >
                  </li>
                </ul>
              </b-col>
              <b-col cols="6">
                <ul class="itemgrid-features list-unstyled">
                  <li>
                    <feather-icon icon="ShoppingCartIcon" />
                    <span>Offre: {{ program.total_lots }} lots</span>
                  </li>
                  <li>
                    <feather-icon icon="CodepenIcon" />
                    <span
                      >Disponible: {{ program.total_available_lots }} lots</span
                    >
                  </li>
                </ul>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-text>

      <hr class="my-1" />

      <b-row>
        <b-col cols="12" md="6">
          <b-form-checkbox
            v-model="withParking"
            class="custom-control-primary"
            name="check-button"
            switch
            v-if="program.price.full_vat_price_per_meter_parking_included"
          >
            Parking {{ withParking ? "inclus" : "exclus" }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <div>
        <b-table :items="grid_lots" :fields="fields" small responsive>
        </b-table>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  VBTooltip,
  BTable,
  BRow,
  BCol,
  BCard,
  BImg,
  BCardText,
  BButton,
  BBadge,
  BOverlay,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useDecimmoReport } from "./../useDecimmoReport";

// Custom formater
import {
  currency,
  formatReverseDate,
  currencyPriceMeters,
} from "@core/utils/filter";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BCard,
    BImg,
    BCardText,
    BButton,
    BBadge,
    BOverlay,
    BSpinner,
    BFormCheckbox,
  },
  props: {
    program: {
      type: Object,
      required: true,
    },
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      withParking: false,
      fundingTypeOptions: [
        {
          code: "Accession sociale",
          value: "PAS",
          description:
            "Le prêt d'accession sociale (PAS) est un prêt immobilier accordé aux personnes ayant des revenus modestes.",
          variant: "primary",
        },
        {
          code: "BRS",
          value: "BRS",
          description:
            "Le bail réel solidaire (BRS) est un dispositif qui permet de dissocier le foncier du bâti pour faire baisser le prix des logements.",
          variant: "info",
        },
        {
          code: "Duflot",
          value: "Duflot",
          description:
            "Le dispositif Duflot ouvre droit à une réduction d’impôt calculée sur le prix de revient des logements neufs ou réhabilités, construits dans certaines zones, à condition de les louer.",
          variant: "info",
        },
        {
          code: "Girardin",
          value: "Girardin",
          description:
            "Le Girardin social permet de défiscaliser en finançant la construction de logements sociaux dans les DOM-TOM.",
          variant: "info",
        },
        {
          code: "PLS investisseur",
          value: "PLSI",
          description:
            "Le Prêt Locatif Social est destiné aux propriétaires souhaitant louer leur bien immobilier à titre de résidence principale.",
          variant: "secondary",
        },
        {
          code: "PSLA",
          value: "PSLA",
          description:
            "Le prêt social location-accession (PSLA) est un dispositif d'accession sociale à la propriété. Il s'adresse à des ménages sous plafonds de ressources qui achètent leur logement agréé par l'Etat.",
          variant: "secondary",
        },
        {
          code: "Pinel",
          value: "Pinel",
          description:
            "Le dispositif d'investissement locatif Pinel permet, sous conditions, d'obtenir une réduction d'impôts pour l'achat d'un logement neuf ou réhabilité.",
          variant: "success",
        },
        {
          code: "Prix maitrisés intermédiaires",
          value: "PMI",
          description:
            "Le logement intermédiaire permet aux ménages trop aisés pour vivre en HLM d'accéder aux logements d'un parc privé.",
          variant: "danger",
        },
        {
          code: "Prix maitrisés",
          value: "PM",
          description:
            "Destiné aux ménages aux revenus modestes, le dispositif d’accession à prix maîtrisé permet de devenir propriétaire dans le neuf, à des conditions avantageuses.",
          variant: "danger",
        },
        {
          code: "Périmètre RU",
          value: "RU",
          description:
            "Périmètre d'intervention des actions pour la rénovation urbaine pour accompagner des projets urbains globaux et transformer des quartiers en profondeur.",
          variant: "warning",
        },
        /* {
          code: "Strictement libre",
          value: "Strictement libre",
          description:
            "Contrairement à l’accession aidée, devenir propriétaire d’un logement neuf en accession libre signifie qu’aucun prêt financé par l’Etat ou par une commune ne vient compléter le financement du projet immobilier.",
          variant: "light-dark",
        }, */
      ],
      fields: [
        {
          key: "type",
          label: "Type",
          tdClass: "limit-col-width-3",
        },
        {
          key: "total",
          label: "Nombre de lots",
          tdClass: "limit-col-width-5",
        },
        {
          key: "area_min",
          label: "Surface minimum",
          formatter: (value) => `${value} m²`,
        },
        {
          key: "area_max",
          label: "Surface maximum",
          formatter: (value) => `${value} m²`,
        },
        {
          key: "price_min",
          label: "Prix minimum",
          formatter: (value) => `${currency(value)}`,
        },
        {
          key: "price_max",
          label: "Prix maximum",
          formatter: (value) => `${currency(value)}`,
        },
      ],
    };
  },
  setup() {
    const { reportData, fetchReportData } = useDecimmoReport();

    return {
      reportData,

      fetchReportData,
    };
  },
  computed: {
    fundingTypeArray() {
      const fundings = [];

      this.program.funding_type.split(";").forEach((element) => {
        if (
          this.fundingTypeOptions.filter((e) => e.code === element).length > 0
        ) {
          fundings.push(
            this.fundingTypeOptions.find((e) => e.code === element)
          );
        }
      });

      return fundings;
    },
    isFull() {
      return this.$store.getters["app-comparator/isFull"];
    },
    inComparator() {
      if (
        this.$store.getters["app-comparator/getPrograms"].find(
          (program) => program.id === this.program.id
        )
      )
        return true;
      return false;
    },
    grid_lots() {
      const types = ["t1", "t2", "t3", "t4", "t5"];
      const grid_lots = [];
      for (const type of types) {
        if (this.program[type].volume.initial_stock > 0) {
          const lot = {
            type,
            total: `${this.program[type].volume.available_offer}/${this.program[type].volume.initial_stock}`,
            area_min: this.program[type].living_area.min
              .toString()
              .split(".")[0],
            area_max: this.program[type].living_area.max
              .toString()
              .split(".")[0],
            price_min: this.withParking
              ? this.program[type].full_vat_price.price_parking_included.min
              : this.program[type].full_vat_price.price_parking_excepted.min,
            price_max: this.withParking
              ? this.program[type].full_vat_price.price_parking_included.max
              : this.program[type].full_vat_price.price_parking_excepted.max,
          };
          grid_lots.push(lot);
        }
      }

      return grid_lots;
    },
  },
  methods: {
    currency,
    formatReverseDate,
    currencyPriceMeters,
    addProgramToComparator() {
      if (!this.inComparator) {
        this.$store.commit(
          "app-comparator/ADD_PROGRAM_TO_COMPARATOR",
          this.program
        );
        this.$swal({
          title: "Programme ajouté au comparateur",
          icon: "success",
          timer: 1500,
          heightAuto: false,
          showConfirmButton: false,
        });
      }
    },
    removeProgramFromComparator() {
      this.$store.commit(
        "app-comparator/REMOVE_PROGRAM_FROM_COMPARATOR",
        this.program.id
      );
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/variables";

.program-img {
  max-height: 300px;
  @media (max-width: 1600px) {
    max-height: 125px !important;
  }
}

.itemgrid-features {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 12px;
  li {
    svg,
    i {
      height: 1.4rem;
      width: 1.4rem;
      font-size: 1.4rem;
      margin-right: 0.75rem;
    }
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    span {
      font-weight: $font-weight-bolder;
    }
  }
}
</style>
