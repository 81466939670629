import store from "@/store";
import { ref } from "@vue/composition-api";

// Leaflet
import { latLng, latLngBounds } from "leaflet";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export const useDecimmoFiltersAndResult = () => {
  const filters = ref({
    address: "Lyon",
    zipCode: "",
    developer: "",
    distance: 5,
    mouseOverProgram: null,
    selectedProgram: {},
    page: 1,
    perPage: 6,
    center: latLng(45.761053, 4.846503),
    bounds: latLngBounds([
      [45.78512285554682, 4.895610809326173],
      [45.73691945326259, 4.797334671020509],
    ]),
    userMarker: latLng(45.761053, 4.846503),
  });

  const filterOptions = {
    distanceOptions: [
      { label: "1km", value: 1 },
      { label: "5km", value: 5 },
      { label: "20km", value: 20 },
      { label: "50km", value: 50 },
    ],
  };

  return {
    // Filter
    filters,
    filterOptions,
  };
};

export const useDecimmoUi = () => {
  const itemView = false;
  const itemViewOptions = [
    { icon: "MapIcon", value: false },
    { icon: "GridIcon", value: true },
  ];

  // Pagination count <= required by pagination component
  const totalPrograms = ref({ count: 0 });

  return {
    itemView,
    itemViewOptions,
    totalPrograms,
  };
};

export const useDecimmoAPI = () => {
  const isBusy = ref(false);
  const isGeoBusy = ref(false);
  const pagination = ref({});
  const programs = ref([]);
  const paginatedPrograms = ref([]);
  const toast = useToast();

  const fetchAddressByLatlng = (latlng) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch("app-decimmo/fetchAddressByLatlng", { latlng })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: "Adresse introuvable",
                icon: "AlertTriangleIcon",
                variant: "warning",
              },
            },
            { position: "top-center" }
          );
          reject(err);
        });
    });
  };

  const fetchSuggestAddress = (query) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch("app-decimmo/fetchSuggestAddress", { query })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: "Adresse introuvable",
                icon: "AlertTriangleIcon",
                variant: "warning",
              },
            },
            { position: "top-center" }
          );
          reject(err);
        });
    });
  };

  const findPrograms = (filters) => {
    const searchListPrograms = {
      page: filters.page,
      page_limit: filters.perPage, // 50 is the limit
      geometries: {
        bounding_box: {
          top: filters.bounds._northEast.lat,
          left: filters.bounds._southWest.lng,
          bottom: filters.bounds._southWest.lat,
          right: filters.bounds._northEast.lng,
        },
      },
      filters: {
        key_value: [],
        range: [
          {
            key: "total_available_lots",
            value_min: "1",
            value_max: "500",
          },
        ],
      },
    };
    return new Promise((resolve, reject) => {
      store
        .dispatch("app-decimmo/listPrograms", searchListPrograms)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: "Décision Immo indisponible.",
                icon: "AlertTriangleIcon",
                variant: "warning",
              },
            },
            { position: "top-center" }
          );
          reject(err);
        });
    });
  };

  const findProgramsCircle = (filters) => {
    const searchListPrograms = {
      page: filters.page,
      page_limit: filters.perPage,
      geometries: {
        circle: {
          distance: filters.distance * 1000, // convert in meters
          center: {
            latitude: filters.center.lat,
            longitude: filters.center.lng,
          },
        },
      },
      filters: {
        key_value: [],
        range: [
          {
            key: "total_available_lots",
            value_min: "1",
            value_max: "200",
          },
        ],
      },
    };

    if (filters.developer.length > 0) {
      searchListPrograms.filters.key_value.push({
        key: "promoter_name",
        value: filters.developer,
      });
    }
    return new Promise((resolve, reject) => {
      store
        .dispatch("app-decimmo/listPrograms", searchListPrograms)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: "Décision Immo indisponible.",
                icon: "AlertTriangleIcon",
                variant: "warning",
              },
            },
            { position: "top-center" }
          );
          reject(err);
        });
    });
  };

  const findProgramsMap = (filters) => {
    const searchListPrograms = {
      page: 1,
      page_limit: 500, // 500 is the limit
      geometries: {
        bounding_box: {
          top: filters.bounds._northEast.lat,
          left: filters.bounds._southWest.lng,
          bottom: filters.bounds._southWest.lat,
          right: filters.bounds._northEast.lng,
        },
      },
      filters: {
        key_value: [],
        range: [
          {
            key: "total_available_lots",
            value_min: "1",
            value_max: "200",
          },
        ],
      },
    };
    return new Promise((resolve, reject) => {
      store
        .dispatch("app-decimmo/listPrograms", searchListPrograms)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: "Décision Immo indisponible.",
                icon: "AlertTriangleIcon",
                variant: "warning",
              },
            },
            { position: "top-center" }
          );
          reject(err);
        });
    });
  };

  return {
    isBusy,
    isGeoBusy,
    pagination,
    programs,
    paginatedPrograms,

    fetchAddressByLatlng,
    fetchSuggestAddress,
    findPrograms,
    findProgramsCircle,
    findProgramsMap,
  };
};
