export default {
  namespaced: true,
  state: {
    programs: [],
  },
  getters: {
    getPrograms: (state) => {
      return state.programs;
    },
    getSize: (state) => {
      return state.programs.length;
    },
    isFull: (state) => {
      if (state.programs.length >= 3) return true;
      return false;
    },
  },
  mutations: {
    ADD_PROGRAM_TO_COMPARATOR(state, program) {
      state.programs.push(program);
    },
    REMOVE_PROGRAM_FROM_COMPARATOR(state, programID) {
      const index = state.programs.findIndex(
        (program) => program.id === programID
      );
      state.programs.splice(index, 1);
    },
    RESET_PROGRAMS_FROM_COMPARATOR(state) {
      state.programs = [];
    },
  },
  actions: {},
};
