<template>
  <!-- Filters' Card -->
  <b-card body-class="py-1">
    <b-row>
      <b-col cols="12">
        <label>Rechercher une adresse</label>
        <vue-autosuggest
          id="selected"
          :suggestions="suggestedAddressOptions"
          :limit="4"
          :input-props="{
            id: 'autosuggest__input',
            class: 'form-control',
            placeholder: '51, Rue du Marché 69300 Caluire-et-Cuire',
          }"
          @input="onInputChange"
          @selected="onSelected"
          @blur="onBlur"
          v-model="filters.address"
        >
          <template slot-scope="{ suggestion }">
            <span class="my-suggestion-item">{{
              suggestion.item.properties.label
            }}</span>
          </template>
        </vue-autosuggest>
      </b-col>
      <b-col cols="12" class="mt-1">
        <label>Distance</label>
        <b-form-radio-group
          v-model="filters.distance"
          :options="filterOptions.distanceOptions"
          value-field="value"
          text-field="label"
          class="mt-50"
        />
      </b-col>
      <b-col cols="12" class="mt-1">
        <label>Promoteur</label>
        <vue-autosuggest
          :suggestions="suggestedDeveloperOptions"
          :limit="6"
          :input-props="{
            id: 'autosuggest__developer',
            class: 'form-control',
            placeholder: 'VINCI Immobilier',
          }"
          @input="onInputDeveloperChange"
          @selected="onSelectedDeveloper"
          :get-suggestion-value="getSuggestionDeveloperValue"
          v-model="developerInput"
        >
          <template slot-scope="{ suggestion }">
            <span>{{ suggestion.item.name }}</span>
          </template>
        </vue-autosuggest>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { VueAutosuggest } from "vue-autosuggest";
import { latLng } from "leaflet";
import { useDecimmoAPI } from "../useDecimmoSearcher";
import { BRow, BCol, BCard, BFormRadioGroup } from "bootstrap-vue";
import _ from "lodash";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormRadioGroup,

    // 3rd Party
    VueAutosuggest,
  },
  directives: {
    Ripple,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      developerInput: "",
      lastSelectedAddress: this.filters.address,
      suggestedAddressOptions: [],
      suggestedDeveloperOptions: [],
    };
  },
  setup() {
    const { fetchSuggestAddress } = useDecimmoAPI();

    return {
      fetchSuggestAddress,
    };
  },
  created() {
    // ADD DEBOUNCE TIMER FOR PERFORMANCE ISSUES
    this.debouncedFetchSuggestions = _.debounce(this.fetchSuggestions, 500);
    this.debouncedFetchSuggestionsDeveloper = _.debounce(
      this.fetchSuggestionsDeveloper,
      500
    );
  },
  methods: {
    // METHODS FOR ADDRESS AUTOSUGGEST
    onBlur() {
      // Force suggestion selection or reset field
      if (
        this.suggestedAddressOptions.length > 0 &&
        this.suggestedAddressOptions[0].data.filter(
          (sugg) => sugg.properties.label.toString() === this.filters.address
        ).length <= 0
      ) {
        this.filters.address = this.lastSelectedAddress;
      } else if (
        this.filters.address !== this.lastSelectedAddress &&
        this.suggestedAddressOptions.length === 0
      ) {
        this.filters.address = this.lastSelectedAddress;
      }
    },
    onSelected(item) {
      // Sync Address
      this.lastSelectedAddress = item.item.properties.label.toString();
      this.filters.address = item.item.properties.label.toString();

      // Sync Center and Marker
      const center = latLng(
        item.item.geometry.coordinates[1],
        item.item.geometry.coordinates[0]
      );
      this.filters.center = center;
      this.filters.userMarker = center;
    },
    fetchSuggestions(text) {
      this.fetchSuggestAddress(text).then((response) => {
        this.suggestedAddressOptions = [
          {
            data: response.data.features,
          },
        ];
      });
    },
    onInputChange(text) {
      if (text === "" || text === undefined) {
        return;
      }
      this.debouncedFetchSuggestions(text);
    },
    // METHODS FOR DEVELOPER AUTOSUGGEST
    getSuggestionDeveloperValue(suggestion) {
      return suggestion.item.name;
    },
    onSelectedDeveloper(item) {
      this.filters.developer = item.item.name;
      this.suggestedDeveloperOptions = [];
    },
    fetchSuggestionsDeveloper(text) {
      this.$store
        .dispatch("app-decimmo/fetchSuggestPromoters", {
          size: 6,
          text_query: text.toUpperCase(),
        })
        .then((response) => {
          this.suggestedDeveloperOptions = [
            {
              data: response.data.promoters,
            },
          ];
        });
    },
    onInputDeveloperChange(text) {
      if (text === "" || text === undefined) {
        this.filters.developer = "";
        return;
      }
      this.debouncedFetchSuggestionsDeveloper(text);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-autosuggest.scss";
@import "@core/scss/vue/libs/vue-slider.scss";
</style>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}

.input-group-text {
  border: 1px solid #d8d6de !important;
  font-size: 1rem !important;
  height: auto !important;
}
</style>
