<template>
  <div>
    <l-map
      ref="map"
      :zoom="zoom"
      :minZoom="14"
      :center.sync="filters.center"
      @update:bounds="onBoundsUpdated"
      @click="updateMarker"
    >
      <l-tile-layer :url="url" :attribution="attribution" />

      <!-- User Marker -->
      <div>
        <l-marker
          ref="userMarker"
          :lat-lng="filters.userMarker"
          :icon="iconFlag"
          :zIndexOffset="100000"
        >
          <l-popup :options="{ autoPan: false }">
            <popup-position :filters="filters" />
          </l-popup>
        </l-marker>
      </div>
      <!-- Programs Markers -->
      <div v-if="programs.length > 0">
        <l-marker
          v-for="program in programs"
          :key="program.id"
          :ref="program.id"
          :lat-lng="[program.location.latitude, program.location.longitude]"
          :icon="iconBuilding"
          @click="onPopupProgramOpen(program.id)"
        >
          <l-popup :options="{ autoPan: false }">
            <popup-program :program="program" />
          </l-popup>
        </l-marker>
      </div>
    </l-map>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import L from "leaflet";

// Custom marker for leaflet map
import "@fortawesome/fontawesome-free/js/all.js";
import "leaflet.awesome-markers/dist/leaflet.awesome-markers.js";
import "leaflet.awesome-markers/dist/leaflet.awesome-markers.css";

// Vue Components
import PopupPosition from "./popup/PopupPosition.vue";
import PopupProgram from "./popup/PopupProgram.vue";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,

    PopupPosition,
    PopupProgram,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    programs: {
      type: Array,
      required: true,
    },
  },
  watch: {
    "filters.mouseOverProgram"(val, oldVal) {
      // if mouse is over program card, animate cooresponding popup
      if (val !== null) {
        this.$refs[`${val}`][0].mapObject.openPopup();
      } else {
        this.$refs[`${oldVal}`][0].mapObject.closePopup();
      }
    },
    "filters.userMarker"(val) {
      // update map marker
      if (this.$refs.userMarker) {
        this.$refs.userMarker.setLatLng([val.lat, val.lng]);
        this.$refs.userMarker.mapObject.openPopup();
      }
    },
  },
  data() {
    return {
      zoom: 15,
      url: this.$config.VUE_APP_MAP_TILE_URL,
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      iconBuilding: L.AwesomeMarkers.icon({
        icon: "building",
        markerColor: "blue",
        iconColor: "white",
        prefix: "fa",
      }),
      iconFlag: L.AwesomeMarkers.icon({
        icon: "flag",
        markerColor: "green",
        iconColor: "white",
        prefix: "fa",
      }),
    };
  },
  methods: {
    updateMarker(e) {
      // update marker value and center map
      this.filters.userMarker = e.latlng;
      this.filters.center = e.latlng;
    },
    onPopupProgramOpen(programID) {
      // if selected program is different, change selectedProgram value
      if (programID !== this.filters.selectedProgram.id) {
        this.filters.selectedProgram = this.programs.find(
          (prog) => prog.id === programID
        );
      }
    },
    onBoundsUpdated(bounds) {
      // update bounds
      this.filters.bounds = bounds;
    },
  },
};
</script>

<style lang="scss">
@import "~leaflet/dist/leaflet.css";

.leaflet-popup-content {
  width: auto !important;
}

.leaflet-container {
  z-index: 1;
}

.awesome-marker svg {
  font-size: 17px;
  margin-top: 9.5px;
}
</style>
