import Vue from "vue";
import * as ApiSituation from "../../axios-generated/situation";
import * as ApiProgram from "../../axios-generated/program";
import * as ApiEstimate from "../../axios-generated/estimate/";
// @ts-ignore
import axios from "@axios";

const getEstimateAPI = () => {
  return new ApiEstimate.EstimatesApi(
    undefined,
    Vue.prototype.$config
      ? Vue.prototype.$config.VUE_APP_ESTIMATE_API_BASE_URL
      : "",
    axios
  );
};

const getProgramsAPI = () => {
  return new ApiProgram.ProgramsApi(
    undefined,
    Vue.prototype.$config
      ? Vue.prototype.$config.VUE_APP_PROGRAM_API_BASE_URL
      : "",
    axios
  );
};

const getAttractivityAPI = () => {
  return new ApiSituation.AttractivenessApi(
    undefined,
    Vue.prototype.$config
      ? Vue.prototype.$config.VUE_APP_SITUATION_API_BASE_URL
      : "",
    axios
  );
};

const getBusinessAPI = () => {
  return new ApiSituation.CommerceApi(
    undefined,
    Vue.prototype.$config
      ? Vue.prototype.$config.VUE_APP_SITUATION_API_BASE_URL
      : "",
    axios
  );
};

const getInseeAPI = () => {
  return new ApiSituation.InseeApi(
    undefined,
    Vue.prototype.$config
      ? Vue.prototype.$config.VUE_APP_SITUATION_API_BASE_URL
      : "",
    axios
  );
};

const getReportsAPI = () => {
  return new ApiSituation.ReportsApi(
    undefined,
    Vue.prototype.$config
      ? Vue.prototype.$config.VUE_APP_SITUATION_API_BASE_URL
      : "",
    axios
  );
};

const getPromotersAPI = () => {
  return new ApiSituation.PromotersApi(
    undefined,
    Vue.prototype.$config
      ? Vue.prototype.$config.VUE_APP_SITUATION_API_BASE_URL
      : "",
    axios
  );
};

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // REPORT LATERAL MAP
    fetchCommune(ctx, { codeInsee }) {
      return new Promise((resolve, reject) => {
        axios
          .get(Vue.prototype.$config.VUE_APP_GOUV_API_GEO_COMMUNES, {
            params: { code: codeInsee, fields: "code,nom,contour,centre" },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // AUTOCOMPLETE
    fetchAddressByLatlng(ctx, { latlng }) {
      return new Promise((resolve, reject) => {
        axios
          .get(Vue.prototype.$config.VUE_APP_GOUV_API_GEO_REVERSE, {
            params: { lon: latlng.lng, lat: latlng.lat },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    async fetchSuggestAddress(ctx, { query }) {
      return new Promise((resolve, reject) => {
        axios
          .get(Vue.prototype.$config.VUE_APP_GOUV_API_GEO_SEARCH, {
            params: { q: query },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    async fetchSuggestPromoters(ctx, searchSizeQuery) {
      return new Promise((resolve, reject) => {
        getPromotersAPI()
          .listPromoters(searchSizeQuery.size, searchSizeQuery.text_query)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // ESTIMATION
    fetchRentEstimation(ctx, searchListRentEvolution) {
      return new Promise((resolve, reject) => {
        getEstimateAPI()
          .fetchRentEstimation(
            searchListRentEvolution.zipCode,
            searchListRentEvolution.nbYears
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // PROGRAMS
    async listPrograms(ctx, searchListPrograms) {
      return new Promise((resolve, reject) => {
        getProgramsAPI()
          .listPrograms(searchListPrograms)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    async getProgramByID(ctx, id) {
      return new Promise((resolve, reject) => {
        getProgramsAPI()
          .getProgramByID(id)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // ATTRACTIVITY
    async listPrixm2ByPeriode(ctx, searchTempoGeo) {
      return new Promise((resolve, reject) => {
        getAttractivityAPI()
          .listPrixm2ByPeriode(
            searchTempoGeo.frequency,
            searchTempoGeo.nb_occurence,
            searchTempoGeo.geo_distances
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    async listPrixm2ByType(ctx, searchTempoGeo) {
      return new Promise((resolve, reject) => {
        getAttractivityAPI()
          .listPrixm2ByType(
            searchTempoGeo.frequency,
            searchTempoGeo.nb_occurence,
            searchTempoGeo.geo_distances
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // BUSINESS
    async listVentesByPeriode(ctx, searchTempoGeo) {
      return new Promise((resolve, reject) => {
        getBusinessAPI()
          .listVentesByPeriode(
            searchTempoGeo.frequency,
            searchTempoGeo.nb_occurence,
            searchTempoGeo.geo_distances
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // INSEE
    async listDemographyByPeriode(ctx, searchYearsLevelCoord) {
      return new Promise((resolve, reject) => {
        getInseeAPI()
          .listDemographyByPeriode(
            searchYearsLevelCoord.nb_years,
            searchYearsLevelCoord.geo_distances
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // REPORT
    async createReport(ctx, newReport) {
      return new Promise((resolve, reject) => {
        getReportsAPI()
          .createReport(newReport)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    async updateReport(ctx, updateReport) {
      return new Promise((resolve, reject) => {
        getReportsAPI()
          .updateReport(updateReport.id, updateReport)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    async findReports(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        getReportsAPI()
          .findReports(
            queryParams.groupId,
            queryParams.sortBy,
            queryParams.perPage,
            queryParams.page,
            queryParams.sortDesc
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    async findReportByID(ctx, id) {
      return new Promise((resolve, reject) => {
        getReportsAPI()
          .findReportByID(id)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    async disableReportByID(ctx, id) {
      return new Promise((resolve, reject) => {
        getReportsAPI()
          .disableReportByID(id)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
