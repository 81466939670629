<template>
  <b-card
    :img-src="program.brochure_url"
    img-left
    img-alt="card img"
    img-width="30%"
    img-height="auto"
    body-class="program-card-font-size"
    @mouseover="onMouseOver(program.id)"
    @mouseleave="onMouseLeave()"
  >
    <b-row>
      <b-col cols="6" class="mb-50">
        <h5 class="mb-0">{{ program.name }}</h5>
      </b-col>
      <b-col cols="6" class="text-right">
        <b-button
          v-if="!inComparator"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="btn-sm small-padding"
          @click.stop="addProgramToComparator"
          :disabled="isFull"
        >
          Comparer
        </b-button>
        <b-button
          v-if="inComparator"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          class="btn-sm small-padding"
          @click.stop="removeProgramFromComparator"
        >
          Retirer
        </b-button>
      </b-col>
      <b-col cols="12">
        <span>par</span>
        <b-link class="text-primary">
          {{ program.promoter_name }}
        </b-link>
      </b-col>
      <b-col class="mt-50">
        <span class="font-weight-500">{{ program.location.sector_name }}</span>
      </b-col>
      <b-col v-if="program.distance > 0" cols="4" class="mt-50 text-right">
        <span>À </span>
        <b-link class="text-primary font-weight-bolder"
          >{{ program.distance }}m</b-link
        >
      </b-col>
    </b-row>

    <hr class="my-50" />

    <b-row class="font-weight-500">
      <b-col xs>
        <feather-icon
          class="list-icon"
          icon="SunriseIcon"
          v-b-tooltip.hover.v-primary
          title="Date de livraison"
        />
        <span>{{ formatReverseDate(program.delivery_date) }}</span>
      </b-col>
      <b-col xs>
        <feather-icon
          class="list-icon"
          icon="CodepenIcon"
          v-b-tooltip.hover.v-primary
          title="Nombre de lots"
        />
        <span
          >{{
            program.total_available_lots + "/" + program.total_lots
          }}
          lots</span
        >
      </b-col>
    </b-row>

    <hr class="my-50" />

    <div class="mb-50">
      <b-row>
        <b-col
          cols="6"
          v-b-tooltip.hover.v-primary
          title="Prix médian évalué sur l'ensemble des lots"
        >
          <span class="text-primary font-weight-bolder">{{
            currencyPriceMeters(
              program.price.full_vat_price_per_meter_parking_excepted
            )
          }}</span>
        </b-col>
        <b-col cols="6">
          <span>{{ evalBetweenType(program) }}</span>
        </b-col>
        <b-col cols="12" class="mt-50">
          <b-badge
            v-for="fundingType in fundingTypeArray"
            :key="fundingType.code"
            :variant="fundingType.variant"
            class="mr-50 mb-50"
            v-b-tooltip.hover.v-primary
            :title="fundingType.description"
          >
            {{ fundingType.value }}
          </b-badge>
          <b-badge
            v-if="!!program.reduced_vat_zone"
            variant="info"
            v-b-tooltip.hover.v-primary
            title="TVA réduite"
          >
            <span>&#8600;</span>TVA
          </b-badge>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  VBTooltip,
  BRow,
  BCol,
  BCard,
  BBadge,
  BLink,
  BButton,
} from "bootstrap-vue";
// Custom formater
import Ripple from "vue-ripple-directive";
import {
  evalBetweenType,
  currencyPriceMeters,
  formatReverseDate,
} from "@core/utils/filter";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BLink,
    BButton,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    program: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fundingTypeOptions: [
        {
          code: "Accession sociale",
          value: "PAS",
          description:
            "Le prêt d'accession sociale (PAS) est un prêt immobilier accordé aux personnes ayant des revenus modestes.",
          variant: "primary",
        },
        {
          code: "BRS",
          value: "BRS",
          description:
            "Le bail réel solidaire (BRS) est un dispositif qui permet de dissocier le foncier du bâti pour faire baisser le prix des logements.",
          variant: "info",
        },
        {
          code: "Duflot",
          value: "Duflot",
          description:
            "Le dispositif Duflot ouvre droit à une réduction d’impôt calculée sur le prix de revient des logements neufs ou réhabilités, construits dans certaines zones, à condition de les louer.",
          variant: "info",
        },
        {
          code: "Girardin",
          value: "Girardin",
          description:
            "Le Girardin social permet de défiscaliser en finançant la construction de logements sociaux dans les DOM-TOM.",
          variant: "info",
        },
        {
          code: "PLS investisseur",
          value: "PLSI",
          description:
            "Le Prêt Locatif Social est destiné aux propriétaires souhaitant louer leur bien immobilier à titre de résidence principale.",
          variant: "secondary",
        },
        {
          code: "PSLA",
          value: "PSLA",
          description:
            "Le prêt social location-accession (PSLA) est un dispositif d'accession sociale à la propriété. Il s'adresse à des ménages sous plafonds de ressources qui achètent leur logement agréé par l'Etat.",
          variant: "secondary",
        },
        {
          code: "Pinel",
          value: "Pinel",
          description:
            "Le dispositif d'investissement locatif Pinel permet, sous conditions, d'obtenir une réduction d'impôts pour l'achat d'un logement neuf ou réhabilité.",
          variant: "success",
        },
        {
          code: "Prix maitrisés intermédiaires",
          value: "PMI",
          description:
            "Le logement intermédiaire permet aux ménages trop aisés pour vivre en HLM d'accéder aux logements d'un parc privé.",
          variant: "danger",
        },
        {
          code: "Prix maitrisés",
          value: "PM",
          description:
            "Destiné aux ménages aux revenus modestes, le dispositif d’accession à prix maîtrisé permet de devenir propriétaire dans le neuf, à des conditions avantageuses.",
          variant: "danger",
        },
        {
          code: "Périmètre RU",
          value: "RU",
          description:
            "Périmètre d'intervention des actions pour la rénovation urbaine pour accompagner des projets urbains globaux et transformer des quartiers en profondeur.",
          variant: "warning",
        },
        /* {
          code: "Strictement libre",
          value: "Strictement libre",
          description:
            "Contrairement à l’accession aidée, devenir propriétaire d’un logement neuf en accession libre signifie qu’aucun prêt financé par l’Etat ou par une commune ne vient compléter le financement du projet immobilier.",
          variant: "light-dark",
        }, */
      ],
    };
  },
  computed: {
    fundingTypeArray() {
      const fundings = [];

      this.program.funding_type.split(";").forEach((element) => {
        if (
          this.fundingTypeOptions.filter((e) => e.code === element).length > 0
        ) {
          fundings.push(
            this.fundingTypeOptions.find((e) => e.code === element)
          );
        }
      });

      return fundings;
    },
    isFull() {
      return this.$store.getters["app-comparator/isFull"];
    },
    inComparator() {
      if (
        this.$store.getters["app-comparator/getPrograms"].find(
          (program) => program.id === this.program.id
        )
      )
        return true;
      return false;
    },
  },
  methods: {
    evalBetweenType,
    currencyPriceMeters,
    formatReverseDate,
    addProgramToComparator() {
      if (!this.inComparator) {
        this.$store.commit(
          "app-comparator/ADD_PROGRAM_TO_COMPARATOR",
          this.program
        );
        this.$swal({
          title: "Programme ajouté au comparateur",
          icon: "success",
          timer: 1500,
          heightAuto: false,
          showConfirmButton: false,
        });
      }
    },
    removeProgramFromComparator() {
      this.$store.commit(
        "app-comparator/REMOVE_PROGRAM_FROM_COMPARATOR",
        this.program.id
      );
    },
    onMouseOver(programId) {
      this.filters.mouseOverProgram = programId;
    },
    onMouseLeave() {
      this.filters.mouseOverProgram = null;
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/variables";

.small-padding {
  padding: 0.196rem 1rem;
}

.font-weight-500 {
  font-weight: 500;
}

.list-icon {
  margin-right: 0.5rem;
  height: 1rem;
  width: 1rem;
}

.card-img-left {
  height: 155px;
  object-fit: cover;
}

.program-card-font-size {
  padding: 1rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  font-size: smaller;
}
</style>
