<template>
  <div style="height: inherit">
    <!-- Overlay -->
    <b-overlay :show="isBusy" no-wrap opacity="0.4" rounded="lg">
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="dark" />
          <b-spinner type="grow" variant="primary" />
          <b-spinner small type="grow" variant="dark" />
          <!-- We add an SR only text for screen readers -->
          <span class="sr-only">Chargement wait...</span>
        </div>
      </template>
    </b-overlay>

    <!-- SideBar Right -->
    <program-sidebar :program="filters.selectedProgram" />

    <div v-if="!isBusy">
      <b-alert
        variant="primary"
        :show="!paginatedPrograms || paginatedPrograms.length === 0"
      >
        <h4 class="alert-heading">Aucun programme n'a été trouvé.</h4>
      </b-alert>
    </div>

    <!-- Programs List -->
    <section class="grid-view double-col-grid-view mt-0">
      <div
        v-for="program in paginatedPrograms"
        :key="program.id"
        @click="selectProgram(program)"
      >
        <program-card
          :program="program"
          :filters="filters"
          class="cursor-pointer item-program"
          v-b-toggle.sidebar-program
        />
      </div>
    </section>

    <!-- Pagination -->
    <section>
      <b-row class="mt-1">
        <b-col cols="12">
          <b-pagination
            v-model="filters.page"
            :total-rows="totalPrograms.count"
            :per-page="filters.perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <div class="sidebar-detached sidebar-left">
        <div class="sidebar">
          <div class="sidebar-shop" :class="{ show: mqShallShowLeftSidebar }">
            <filter-card :filters="filters" :filter-options="filterOptions" />
          </div>
        </div>

        <div
          class="body-content-overlay"
          :class="{ show: mqShallShowLeftSidebar }"
          @click="$emit('update:mq-shall-show-left-sidebar', false)"
        />
      </div>
    </portal>
  </div>
</template>

<script>
import {
  BOverlay,
  BSpinner,
  BRow,
  BCol,
  BPagination,
  BAlert,
  VBToggle,
} from "bootstrap-vue";
import { useDecimmoAPI } from "../useDecimmoSearcher";
import { latLngBounds } from "leaflet";
import destination from "@turf/destination";
import _ from "lodash";
import FilterCard from "./FilterCard.vue";
import ProgramCard from "../shared/ProgramCard";
import ProgramSidebar from "../shared/ProgramSidebar.vue";

export default {
  components: {
    BOverlay,
    BSpinner,
    BRow,
    BCol,
    BPagination,
    BAlert,

    // Filters Card
    FilterCard,
    ProgramCard,
    ProgramSidebar,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    totalPrograms: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    "filters.center"() {
      this.updateBounds();
      this.debouncedFetchedProgramsGrid();
    },
    "filters.developer"() {
      this.debouncedFetchedProgramsGrid();
    },
    "filters.distance"() {
      this.updateBounds();
      this.debouncedFetchedProgramsGrid();
    },
    "filters.page"() {
      this.debouncedFetchedProgramsGrid();
    },
  },
  setup(props) {
    const { findProgramsCircle, paginatedPrograms, isBusy } = useDecimmoAPI();

    const fetchProgramsGrid = (filters) => {
      isBusy.value = true;
      findProgramsCircle(filters)
        .then((httpResp) => {
          isBusy.value = false;
          if (
            httpResp.data.programs !== null &&
            httpResp.data.programs.length > 0
          ) {
            props.totalPrograms.count = httpResp.data.pagination.total;
            paginatedPrograms.value = httpResp.data.programs;
          } else {
            props.totalPrograms.count = 0;
            paginatedPrograms.value = [];
          }
        })
        .catch(() => {
          isBusy.value = false;
        });
    };

    return {
      paginatedPrograms,
      isBusy,

      fetchProgramsGrid,
    };
  },
  created() {
    this.debouncedFetchedProgramsGrid = _.debounce(
      this.fetchProgramsFiltered,
      600
    );

    this.filters.page = 1;
    this.fetchProgramsFiltered();
  },
  methods: {
    updateBounds() {
      const northEast = destination(
        [this.filters.center.lng, this.filters.center.lat],
        this.filters.distance,
        45,
        { units: "kilometers" }
      );
      const southWest = destination(
        [this.filters.center.lng, this.filters.center.lat],
        this.filters.distance,
        -135,
        { units: "kilometers" }
      );
      this.filters.bounds = latLngBounds([
        [northEast.geometry.coordinates[1], northEast.geometry.coordinates[0]],
        [southWest.geometry.coordinates[1], southWest.geometry.coordinates[0]],
      ]);
    },
    selectProgram(program) {
      this.filters.selectedProgram = program;
    },
    fetchProgramsFiltered() {
      this.fetchProgramsGrid(this.filters);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/variables";

.double-col-grid-view {
  grid-template-columns: 1fr 1fr !important;
}

.item-program {
  box-shadow: 0 4px 25px 0 rgba($black, 0.1) !important;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 25px 0 rgba($black, 0.2) !important;
  }
}
</style>
