<template>
  <b-row class="popup-container">
    <!-- SideBar -->

    <b-col sm="4">
      <img :src="program.brochure_url" class="popup-img" />
    </b-col>
    <b-col sm="8">
      <div class="text-left">
        <h5>{{ program.name }}</h5>
      </div>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="primary"
        class="popup-button"
        v-b-toggle.sidebar-program
      >
        Consulter
        <feather-icon type="submit" icon="FileTextIcon" class="ml-50" />
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BButton, VBToggle } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  props: {
    program: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
.popup-container {
  width: 27rem !important;
}

.popup-img {
  height: 5rem !important;
  object-fit: contains;
  width: 100%;
  max-width: 13rem;
}

.popup-button {
  width: 100%;
}
</style>
