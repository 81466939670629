<template>
  <b-row class="mr-50">
    <b-col xs cols="3">
      <img :src="program.brochure_url" class="program-dropdown-img" />
    </b-col>
    <b-col xs cols="9">
      <div class="floating-trash-button">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline"
          @click="
            $store.commit(
              'app-comparator/REMOVE_PROGRAM_FROM_COMPARATOR',
              program.id
            )
          "
        >
          <feather-icon icon="Trash2Icon" />
        </b-button>
      </div>

      <div class="text-left mt-1 mb-0">
        <h5 class="mb-0">{{ program.name }}</h5>
      </div>

      <hr class="my-50 mr-2" />

      <div class="mt-1">
        <b-row>
          <b-col
            xs
            class="text-left"
            v-b-tooltip.hover.v-primary
            title="Prix médian évalué sur l'ensemble des lots"
          >
            <h6 class="text-primary">
              {{
                currencyPriceMeters(
                  program.price.full_vat_price_per_meter_parking_excepted
                )
              }}
            </h6>
          </b-col>
          <b-col xs class="text-right">
            <b-badge
              v-if="program.funding_type.includes('Pinel')"
              variant="success"
              v-b-tooltip.hover.v-primary
              title="Avantage Pinel"
            >
              Pinel
            </b-badge>
            <b-badge
              v-if="!!program.reduced_vat_zone"
              variant="info"
              v-b-tooltip.hover.v-primary
              title="TVA réduite"
            >
              <span>&#8600;</span>TVA
            </b-badge>
          </b-col>
        </b-row>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { VBTooltip, BRow, BCol, BBadge, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
// Custom formater
import { currencyPriceMeters } from "@core/utils/filter";

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BButton,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    program: {
      type: Object,
      required: true,
    },
  },
  methods: {
    currencyPriceMeters,
    evalBetweenType(program) {
      const types = ["t1", "t2", "t3", "t4", "t5"];
      let minType = "";
      let maxType = "";
      for (const type of types) {
        if (program[type].volume.initial_stock > 0) {
          minType = type.toUpperCase();
          break;
        }
      }

      const typesReverse = types.reverse();
      for (const type of typesReverse) {
        if (program[type].volume.initial_stock > 0) {
          maxType = type.toUpperCase();
          break;
        }
      }

      return `Du ${minType} au ${maxType}`;
    },
  },
};
</script>

<style lang="scss">
.program-dropdown-img {
  height: 6rem !important;
  object-fit: contains;
  width: 100%;
  max-width: 8rem;
}

.font-weight-500 {
  font-weight: 500;
}

.floating-trash-button {
  position: absolute;
  right: 0rem;
}
</style>
