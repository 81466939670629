/* tslint:disable */
/* eslint-disable */
/**
 * API Emplacement (full version)
 * L\'API Emplacement permet d\'accéder à de nombreux indicateurs caratérisant le dynamisme immobilier d\'un secteur géographique. Les indicateurs sont calculés à partir de l\'aggrégation de plusieurs sources de données.  # Authentication  Authentification par token JWT généré à partir du backoffice client \'Cadre de Vie\'.  <!-- ReDoc-Inject: <security-definitions> -->
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: cdv-si@cadredevie.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * Répartition par age.
 * @export
 * @interface AgeDivision
 */
export interface AgeDivision {
    /**
     * Population pour la tranche d\'âge 0-14 ans.
     * @type {number}
     * @memberof AgeDivision
     */
    'age_0_14'?: number;
    /**
     * Population pour la tranche d\'âge 15-29 ans.
     * @type {number}
     * @memberof AgeDivision
     */
    'age_15_29'?: number;
    /**
     * Population pour la tranche d\'âge 30-44 ans.
     * @type {number}
     * @memberof AgeDivision
     */
    'age_30_44'?: number;
    /**
     * Population pour la tranche d\'âge 45-59 ans.
     * @type {number}
     * @memberof AgeDivision
     */
    'age_45_59'?: number;
    /**
     * Population pour la tranche d\'âge 60-74 ans.
     * @type {number}
     * @memberof AgeDivision
     */
    'age_60_74'?: number;
    /**
     * Population pour la tranche d\'âge 75-89 ans.
     * @type {number}
     * @memberof AgeDivision
     */
    'age_75_89'?: number;
    /**
     * Population pour la tranche d\'âge 90 ans et plus.
     * @type {number}
     * @memberof AgeDivision
     */
    'age_90_more'?: number;
}
/**
 * 
 * @export
 * @interface BuildingDivision
 */
export interface BuildingDivision {
    /**
     * Nombre de maison.
     * @type {number}
     * @memberof BuildingDivision
     */
    'house'?: number;
    /**
     * Nombre d\'appartement.
     * @type {number}
     * @memberof BuildingDivision
     */
    'appartment'?: number;
    /**
     * Total.
     * @type {number}
     * @memberof BuildingDivision
     */
    'total'?: number;
}
/**
 * Indicateurs du logement des ménages.
 * @export
 * @interface DivisionHousehold
 */
export interface DivisionHousehold {
    /**
     * 
     * @type {HouseholdDivision}
     * @memberof DivisionHousehold
     */
    'division'?: HouseholdDivision;
}
/**
 * Indicateurs d\'activité économique.
 * @export
 * @interface EmploymentInfo
 */
export interface EmploymentInfo {
    /**
     * Total travailleurs.
     * @type {number}
     * @memberof EmploymentInfo
     */
    'total_workers'?: number;
    /**
     * Taux de personnes salariées.
     * @type {number}
     * @memberof EmploymentInfo
     */
    'employee_rate'?: number;
    /**
     * Taux d\'évolution de l\'emploi depuis la dernière année d\'analyse.
     * @type {number}
     * @memberof EmploymentInfo
     */
    'employment_evolution_rate'?: number;
    /**
     * Taux d\'évolution du chômage depuis la dernière année d\'analyse.
     * @type {number}
     * @memberof EmploymentInfo
     */
    'unemployment_evolution_rate'?: number;
    /**
     * Taux de chômage.
     * @type {number}
     * @memberof EmploymentInfo
     */
    'unemployment_rate'?: number;
    /**
     * 
     * @type {EmploymentInfoPsc}
     * @memberof EmploymentInfo
     */
    'psc'?: EmploymentInfoPsc;
}
/**
 * 
 * @export
 * @interface EmploymentInfoPsc
 */
export interface EmploymentInfoPsc {
    /**
     * Taux agriculteurs exploitants
     * @type {number}
     * @memberof EmploymentInfoPsc
     */
    'farmer_rate'?: number;
    /**
     * Taux artisans, commerçants, chefs entreprise
     * @type {number}
     * @memberof EmploymentInfoPsc
     */
    'entrepreneur_rate'?: number;
    /**
     * Taux chomeurs
     * @type {number}
     * @memberof EmploymentInfoPsc
     */
    'without_activity_rate'?: number;
    /**
     * Taux cadres et professions intellectuelles supérieures
     * @type {number}
     * @memberof EmploymentInfoPsc
     */
    'senior_executive_rate'?: number;
    /**
     * Taux employés
     * @type {number}
     * @memberof EmploymentInfoPsc
     */
    'employee_rate'?: number;
    /**
     * Taux ouvriers
     * @type {number}
     * @memberof EmploymentInfoPsc
     */
    'worker_rate'?: number;
    /**
     * Taux professions intermédiaires
     * @type {number}
     * @memberof EmploymentInfoPsc
     */
    'intermediaite_profession_rate'?: number;
    /**
     * Taux retraités
     * @type {number}
     * @memberof EmploymentInfoPsc
     */
    'retired_rate'?: number;
}
/**
 * Division des familles.
 * @export
 * @interface FamilyDivision
 */
export interface FamilyDivision {
    /**
     * Avec enfant.
     * @type {number}
     * @memberof FamilyDivision
     */
    'with_child'?: number;
    /**
     * Sans enfant.
     * @type {number}
     * @memberof FamilyDivision
     */
    'without_child'?: number;
    /**
     * Parent seul.
     * @type {number}
     * @memberof FamilyDivision
     */
    'single_parent'?: number;
    /**
     * Autre.
     * @type {number}
     * @memberof FamilyDivision
     */
    'other'?: number;
}
/**
 * 
 * @export
 * @interface GeoBoundingBox
 */
export interface GeoBoundingBox {
    /**
     * Latitude
     * @type {number}
     * @memberof GeoBoundingBox
     */
    'lat': number;
    /**
     * Longitude
     * @type {number}
     * @memberof GeoBoundingBox
     */
    'lng': number;
}
/**
 * 
 * @export
 * @interface GeoDistances
 */
export interface GeoDistances {
    /**
     * Latitude
     * @type {number}
     * @memberof GeoDistances
     */
    'lat': number;
    /**
     * Longitude
     * @type {number}
     * @memberof GeoDistances
     */
    'lng': number;
    /**
     * Distance (km) > 0
     * @type {number}
     * @memberof GeoDistances
     */
    'distance': number;
}
/**
 * Division ménages.
 * @export
 * @interface HouseholdDivision
 */
export interface HouseholdDivision {
    /**
     * Nombre de famille.
     * @type {number}
     * @memberof HouseholdDivision
     */
    'family'?: number;
    /**
     * 
     * @type {FamilyDivision}
     * @memberof HouseholdDivision
     */
    'family_division'?: FamilyDivision;
    /**
     * Nombre de personne seule.
     * @type {number}
     * @memberof HouseholdDivision
     */
    'single_person'?: number;
    /**
     * 
     * @type {SinglePersonDivision}
     * @memberof HouseholdDivision
     */
    'single_person_division'?: SinglePersonDivision;
}
/**
 * Indicateurs des ménages.
 * @export
 * @interface HouseholdInfo
 */
export interface HouseholdInfo {
    /**
     * 
     * @type {LastYearRotation}
     * @memberof HouseholdInfo
     */
    'last_year_rotation'?: LastYearRotation;
    /**
     * 
     * @type {MoveInAge}
     * @memberof HouseholdInfo
     */
    'move_in_age'?: MoveInAge;
}
/**
 * Evolution des emménagements.
 * @export
 * @interface LastYearRotation
 */
export interface LastYearRotation {
    /**
     * Population habitant auparavant dans le même logement.
     * @type {number}
     * @memberof LastYearRotation
     */
    'same_lodgment'?: number;
    /**
     * Population habitant auparavant dans un autre logement de la même commune.
     * @type {number}
     * @memberof LastYearRotation
     */
    'change_lodgment_same_city'?: number;
    /**
     * Population habitant auparavant dans un autre logement de la même agglomeration.
     * @type {number}
     * @memberof LastYearRotation
     */
    'change_city_same_agglomeration'?: number;
    /**
     * Population habitant auparavant dans un autre logement dans le même département.
     * @type {number}
     * @memberof LastYearRotation
     */
    'change_city_same_department'?: number;
    /**
     * Population habitant auparavant dans la même région dans un autre département.
     * @type {number}
     * @memberof LastYearRotation
     */
    'change_departement_same_region'?: number;
    /**
     * Population habitant auparavant dans une région sur un autre continent.
     * @type {number}
     * @memberof LastYearRotation
     */
    'change_region_mainland'?: number;
    /**
     * Population habitant auparavant à l\'étrangé.
     * @type {number}
     * @memberof LastYearRotation
     */
    'change_no_mainland'?: number;
}
/**
 * 
 * @export
 * @interface ListDemography
 */
export interface ListDemography {
    /**
     * 
     * @type {string}
     * @memberof ListDemography
     */
    'insee_code'?: string;
    /**
     * Année de dernière mise à jour des données.
     * @type {number}
     * @memberof ListDemography
     */
    'year_last_update'?: number;
    /**
     * Nom du secteur de recherche.
     * @type {string}
     * @memberof ListDemography
     */
    'sector_name'?: string;
    /**
     * 
     * @type {Array<ListDemographyDataInner>}
     * @memberof ListDemography
     */
    'data'?: Array<ListDemographyDataInner>;
}
/**
 * 
 * @export
 * @interface ListDemographyDataInner
 */
export interface ListDemographyDataInner {
    /**
     * Année de l\'analyse.
     * @type {string}
     * @memberof ListDemographyDataInner
     */
    'year'?: string;
    /**
     * 
     * @type {PopulationInfo}
     * @memberof ListDemographyDataInner
     */
    'population'?: PopulationInfo;
    /**
     * 
     * @type {LodgmentInfo}
     * @memberof ListDemographyDataInner
     */
    'lodgment'?: LodgmentInfo;
    /**
     * 
     * @type {EmploymentInfo}
     * @memberof ListDemographyDataInner
     */
    'employment'?: EmploymentInfo;
    /**
     * 
     * @type {HouseholdInfo}
     * @memberof ListDemographyDataInner
     */
    'household'?: HouseholdInfo;
}
/**
 * 
 * @export
 * @interface ListPriceMeterPeriod
 */
export interface ListPriceMeterPeriod {
    /**
     * 
     * @type {string}
     * @memberof ListPriceMeterPeriod
     */
    'insee_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListPriceMeterPeriod
     */
    'period_last_update'?: string;
    /**
     * 
     * @type {Array<PricemeterPeriod>}
     * @memberof ListPriceMeterPeriod
     */
    'data'?: Array<PricemeterPeriod>;
}
/**
 * 
 * @export
 * @interface ListPriceMeterType
 */
export interface ListPriceMeterType {
    /**
     * 
     * @type {string}
     * @memberof ListPriceMeterType
     */
    'insee_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListPriceMeterType
     */
    'period_last_update'?: string;
    /**
     * 
     * @type {Array<ListPriceMeterTypeDataInner>}
     * @memberof ListPriceMeterType
     */
    'data'?: Array<ListPriceMeterTypeDataInner>;
}
/**
 * 
 * @export
 * @interface ListPriceMeterTypeDataInner
 */
export interface ListPriceMeterTypeDataInner {
    /**
     * 
     * @type {string}
     * @memberof ListPriceMeterTypeDataInner
     */
    'period'?: string;
    /**
     * 
     * @type {PricemeterTypologie}
     * @memberof ListPriceMeterTypeDataInner
     */
    'typology'?: PricemeterTypologie;
}
/**
 * 
 * @export
 * @interface ListPromoters
 */
export interface ListPromoters {
    /**
     * 
     * @type {ListPromotersPagination}
     * @memberof ListPromoters
     */
    'pagination'?: ListPromotersPagination;
    /**
     * 
     * @type {Array<ListPromotersPromotersInner>}
     * @memberof ListPromoters
     */
    'promoters'?: Array<ListPromotersPromotersInner>;
}
/**
 * 
 * @export
 * @interface ListPromotersPagination
 */
export interface ListPromotersPagination {
    /**
     * Numéro page.
     * @type {number}
     * @memberof ListPromotersPagination
     */
    'page'?: number;
    /**
     * Taille de la page.
     * @type {number}
     * @memberof ListPromotersPagination
     */
    'page_size'?: number;
    /**
     * Total de résultat.
     * @type {number}
     * @memberof ListPromotersPagination
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface ListPromotersPromotersInner
 */
export interface ListPromotersPromotersInner {
    /**
     * Nom du promoteur.
     * @type {string}
     * @memberof ListPromotersPromotersInner
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ListSales
 */
export interface ListSales {
    /**
     * 
     * @type {string}
     * @memberof ListSales
     */
    'insee_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListSales
     */
    'period_last_update'?: string;
    /**
     * 
     * @type {Pinel}
     * @memberof ListSales
     */
    'pinel'?: Pinel;
    /**
     * 
     * @type {Array<Sale>}
     * @memberof ListSales
     */
    'data'?: Array<Sale>;
}
/**
 * Division des logements.
 * @export
 * @interface LodgmentDivision
 */
export interface LodgmentDivision {
    /**
     * Number of properties.
     * @type {number}
     * @memberof LodgmentDivision
     */
    'total_lodgments'?: number;
    /**
     * Taux de résidences principales.
     * @type {number}
     * @memberof LodgmentDivision
     */
    'main_lodgment_rate'?: number;
    /**
     * Taux de résidences secondaires.
     * @type {number}
     * @memberof LodgmentDivision
     */
    'secondary_lodgement_rate'?: number;
    /**
     * Taux de résidences vides.
     * @type {number}
     * @memberof LodgmentDivision
     */
    'empty_lodgement_rate'?: number;
    /**
     * Taux de maison.
     * @type {number}
     * @memberof LodgmentDivision
     */
    'house_rate'?: number;
    /**
     * Taux d\'appartement.
     * @type {number}
     * @memberof LodgmentDivision
     */
    'apartment_rate'?: number;
    /**
     * 
     * @type {MainLodgmentRoomsDivision}
     * @memberof LodgmentDivision
     */
    'main_lodgment_rooms_division'?: MainLodgmentRoomsDivision;
    /**
     * 
     * @type {MainLodgmentBuildingTimeDivision}
     * @memberof LodgmentDivision
     */
    'main_lodgment_building_time_division'?: MainLodgmentBuildingTimeDivision;
    /**
     * 
     * @type {DivisionHousehold}
     * @memberof LodgmentDivision
     */
    'household'?: DivisionHousehold;
}
/**
 * Indicateurs sur le logement des ménages.
 * @export
 * @interface LodgmentHousehold
 */
export interface LodgmentHousehold {
    /**
     * Nombre de ménages imposés.
     * @type {number}
     * @memberof LodgmentHousehold
     */
    'total_taxable_household'?: number;
    /**
     * Taux de ménages imposables.
     * @type {number}
     * @memberof LodgmentHousehold
     */
    'rate_taxable_household'?: number;
    /**
     * Revenu moyen des ménages.
     * @type {number}
     * @memberof LodgmentHousehold
     */
    'average_salary_household'?: number;
}
/**
 * Indicateurs d\'occupation des logements.
 * @export
 * @interface LodgmentInfo
 */
export interface LodgmentInfo {
    /**
     * 
     * @type {LodgmentDivision}
     * @memberof LodgmentInfo
     */
    'division'?: LodgmentDivision;
    /**
     * 
     * @type {LodgmentOccupation}
     * @memberof LodgmentInfo
     */
    'occupation'?: LodgmentOccupation;
    /**
     * 
     * @type {LodgmentHousehold}
     * @memberof LodgmentInfo
     */
    'household'?: LodgmentHousehold;
}
/**
 * Occupation des logements.
 * @export
 * @interface LodgmentOccupation
 */
export interface LodgmentOccupation {
    /**
     * Taux de propriétaires.
     * @type {number}
     * @memberof LodgmentOccupation
     */
    'owner_rate'?: number;
    /**
     * Taux de locataires.
     * @type {number}
     * @memberof LodgmentOccupation
     */
    'tenant_rate'?: number;
    /**
     * Taux de locataires socialement aidés.
     * @type {number}
     * @memberof LodgmentOccupation
     */
    'social_tenant_rate'?: number;
    /**
     * Taux de locataires hébergés gratuitement.
     * @type {number}
     * @memberof LodgmentOccupation
     */
    'free_tenant_rate'?: number;
}
/**
 * Division des résidences principales par ancièneté de contruction et par typologie.
 * @export
 * @interface MainLodgmentBuildingTimeDivision
 */
export interface MainLodgmentBuildingTimeDivision {
    /**
     * 
     * @type {BuildingDivision}
     * @memberof MainLodgmentBuildingTimeDivision
     */
    'before_1919'?: BuildingDivision;
    /**
     * 
     * @type {BuildingDivision}
     * @memberof MainLodgmentBuildingTimeDivision
     */
    'from_1919_to_1945'?: BuildingDivision;
    /**
     * 
     * @type {BuildingDivision}
     * @memberof MainLodgmentBuildingTimeDivision
     */
    'from_1946_to_1970'?: BuildingDivision;
    /**
     * 
     * @type {BuildingDivision}
     * @memberof MainLodgmentBuildingTimeDivision
     */
    'from_1971_to_1990'?: BuildingDivision;
    /**
     * 
     * @type {BuildingDivision}
     * @memberof MainLodgmentBuildingTimeDivision
     */
    'from_1991_to_2005'?: BuildingDivision;
    /**
     * 
     * @type {BuildingDivision}
     * @memberof MainLodgmentBuildingTimeDivision
     */
    'from_2006_to_2015'?: BuildingDivision;
}
/**
 * Division des résidences principales par nombre de pièce.
 * @export
 * @interface MainLodgmentRoomsDivision
 */
export interface MainLodgmentRoomsDivision {
    /**
     * Total T1.
     * @type {number}
     * @memberof MainLodgmentRoomsDivision
     */
    't1'?: number;
    /**
     * Total T2.
     * @type {number}
     * @memberof MainLodgmentRoomsDivision
     */
    't2'?: number;
    /**
     * Total T3.
     * @type {number}
     * @memberof MainLodgmentRoomsDivision
     */
    't3'?: number;
    /**
     * Total T4.
     * @type {number}
     * @memberof MainLodgmentRoomsDivision
     */
    't4'?: number;
    /**
     * Total T5.
     * @type {number}
     * @memberof MainLodgmentRoomsDivision
     */
    't5'?: number;
}
/**
 * 
 * @export
 * @interface Metric
 */
export interface Metric {
    /**
     * Date time interval
     * @type {string}
     * @memberof Metric
     */
    'time'?: string;
    /**
     * Http request count in time interval
     * @type {number}
     * @memberof Metric
     */
    'count'?: number;
}
/**
 * A representation of an errorMessage
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * error Message
     * @type {string}
     * @memberof ModelError
     */
    'errorMessage'?: string;
}
/**
 * Ancienneté d\'emménagement dans la résidence principale.
 * @export
 * @interface MoveInAge
 */
export interface MoveInAge {
    /**
     * Depuis moins de 2 ans.
     * @type {number}
     * @memberof MoveInAge
     */
    'age_0_2'?: number;
    /**
     * De 2 à 4 ans.
     * @type {number}
     * @memberof MoveInAge
     */
    'age_2_4'?: number;
    /**
     * De 5 à 9 ans.
     * @type {number}
     * @memberof MoveInAge
     */
    'age_5_9'?: number;
    /**
     * 10 ans ou plus.
     * @type {number}
     * @memberof MoveInAge
     */
    'age_10_more'?: number;
}
/**
 * 
 * @export
 * @interface NewReport
 */
export interface NewReport {
    /**
     * Nom du raport
     * @type {string}
     * @memberof NewReport
     */
    'name': string;
    /**
     * ID de regroupement des rapports (ref, uuid, etc..)
     * @type {string}
     * @memberof NewReport
     */
    'group_id': string;
    /**
     * latitude
     * @type {number}
     * @memberof NewReport
     */
    'latitude': number;
    /**
     * longitude (\'lng\' for Google Maps API... but use \'lon\' for geoJSON, elastic or GIS...)
     * @type {number}
     * @memberof NewReport
     */
    'longitude': number;
    /**
     * Report comments json data as string
     * @type {string}
     * @memberof NewReport
     */
    'comments'?: string;
    /**
     * Program json data as string
     * @type {string}
     * @memberof NewReport
     */
    'program'?: string;
    /**
     * DeveloperPrograms json data as string
     * @type {string}
     * @memberof NewReport
     */
    'developerPrograms'?: string;
    /**
     * EcosystemPrograms json data as string
     * @type {string}
     * @memberof NewReport
     */
    'ecosystemPrograms'?: string;
    /**
     * Prixm2ByPeriode json data as string
     * @type {string}
     * @memberof NewReport
     */
    'prixm2ByPeriode': string;
    /**
     * Prixm2ByType json data as string
     * @type {string}
     * @memberof NewReport
     */
    'prixm2ByType': string;
    /**
     * Rentm2ByPeriode json data as string
     * @type {string}
     * @memberof NewReport
     */
    'rentm2ByPeriode'?: string;
    /**
     * Prixm2ByPeriode json data as string
     * @type {string}
     * @memberof NewReport
     */
    'ventesByPeriode': string;
    /**
     * Prixm2ByPeriode json data as string
     * @type {string}
     * @memberof NewReport
     */
    'demographyByPeriode': string;
}
/**
 * 
 * @export
 * @interface PartialReport
 */
export interface PartialReport {
    /**
     * Unique id (format UUID) of the Report
     * @type {string}
     * @memberof PartialReport
     */
    'ID'?: string;
    /**
     * Nom du raport
     * @type {string}
     * @memberof PartialReport
     */
    'name'?: string;
    /**
     * Program json data as string
     * @type {string}
     * @memberof PartialReport
     */
    'program'?: string;
    /**
     * Type of the Report
     * @type {string}
     * @memberof PartialReport
     */
    'type'?: PartialReportTypeEnum;
    /**
     * Status of the Report
     * @type {string}
     * @memberof PartialReport
     */
    'status'?: PartialReportStatusEnum;
    /**
     * Created Product date (Format ISO 8601)
     * @type {string}
     * @memberof PartialReport
     */
    'created_at'?: string;
    /**
     * Last update Product date (Format ISO 8601)
     * @type {string}
     * @memberof PartialReport
     */
    'updated_at'?: string;
}

export const PartialReportTypeEnum = {
    Emplacement: 'EMPLACEMENT',
    Programme: 'PROGRAMME'
} as const;

export type PartialReportTypeEnum = typeof PartialReportTypeEnum[keyof typeof PartialReportTypeEnum];
export const PartialReportStatusEnum = {
    Enabled: 'ENABLED',
    Disabled: 'DISABLED'
} as const;

export type PartialReportStatusEnum = typeof PartialReportStatusEnum[keyof typeof PartialReportStatusEnum];

/**
 * 
 * @export
 * @interface Pinel
 */
export interface Pinel {
    /**
     * Zonage pinel.
     * @type {string}
     * @memberof Pinel
     */
    'zoning'?: string;
    /**
     * Plafond des loyers maximum en dispositif pinel.
     * @type {number}
     * @memberof Pinel
     */
    'rent'?: number;
}
/**
 * Indicateurs de répartition de la population.
 * @export
 * @interface PopulationInfo
 */
export interface PopulationInfo {
    /**
     * Total population.
     * @type {number}
     * @memberof PopulationInfo
     */
    'population'?: number;
    /**
     * Taux d\'évolution de la population depuis la dernière année d\'analyse.
     * @type {number}
     * @memberof PopulationInfo
     */
    'evolution_population_rate'?: number;
    /**
     * 
     * @type {AgeDivision}
     * @memberof PopulationInfo
     */
    'age_division'?: AgeDivision;
}
/**
 * 
 * @export
 * @interface PricemeterPeriod
 */
export interface PricemeterPeriod {
    /**
     * 
     * @type {string}
     * @memberof PricemeterPeriod
     */
    'period'?: string;
    /**
     * Prix m² moyen d\'un logement neuf (avec parking).
     * @type {number}
     * @memberof PricemeterPeriod
     */
    'price_meter_new_lodgment_with_parking'?: number;
    /**
     * Prix m² moyen d\'un logement neuf (sans parking).
     * @type {number}
     * @memberof PricemeterPeriod
     */
    'price_meter_new_lodgment_without_parking'?: number;
    /**
     * Prix m² moyen d\'un logement ancien.
     * @type {number}
     * @memberof PricemeterPeriod
     */
    'price_meter_old_lodgment'?: number;
}
/**
 * 
 * @export
 * @interface PricemeterType
 */
export interface PricemeterType {
    /**
     * Prix m² moyen d\'un logement neuf (avec parking).
     * @type {number}
     * @memberof PricemeterType
     */
    'price_meter_new_lodgment_with_parking'?: number;
    /**
     * Prix m² moyen d\'un logement neuf (sans parking).
     * @type {number}
     * @memberof PricemeterType
     */
    'price_meter_new_lodgment_without_parking'?: number;
    /**
     * Prix m² moyen d\'un logement ancien.
     * @type {number}
     * @memberof PricemeterType
     */
    'price_meter_old_lodgment'?: number;
}
/**
 * 
 * @export
 * @interface PricemeterTypologie
 */
export interface PricemeterTypologie {
    /**
     * 
     * @type {PricemeterType}
     * @memberof PricemeterTypologie
     */
    't1'?: PricemeterType;
    /**
     * 
     * @type {PricemeterType}
     * @memberof PricemeterTypologie
     */
    't2'?: PricemeterType;
    /**
     * 
     * @type {PricemeterType}
     * @memberof PricemeterTypologie
     */
    't3'?: PricemeterType;
    /**
     * 
     * @type {PricemeterType}
     * @memberof PricemeterTypologie
     */
    't4'?: PricemeterType;
    /**
     * 
     * @type {PricemeterType}
     * @memberof PricemeterTypologie
     */
    't5'?: PricemeterType;
}
/**
 * 
 * @export
 * @interface Report
 */
export interface Report {
    /**
     * Nom du raport
     * @type {string}
     * @memberof Report
     */
    'name': string;
    /**
     * ID de regroupement des rapports (ref, uuid, etc..)
     * @type {string}
     * @memberof Report
     */
    'group_id': string;
    /**
     * latitude
     * @type {number}
     * @memberof Report
     */
    'latitude': number;
    /**
     * longitude (\'lng\' for Google Maps API... but use \'lon\' for geoJSON, elastic or GIS...)
     * @type {number}
     * @memberof Report
     */
    'longitude': number;
    /**
     * Report comments json data as string
     * @type {string}
     * @memberof Report
     */
    'comments'?: string;
    /**
     * Program json data as string
     * @type {string}
     * @memberof Report
     */
    'program'?: string;
    /**
     * DeveloperPrograms json data as string
     * @type {string}
     * @memberof Report
     */
    'developerPrograms'?: string;
    /**
     * EcosystemPrograms json data as string
     * @type {string}
     * @memberof Report
     */
    'ecosystemPrograms'?: string;
    /**
     * Prixm2ByPeriode json data as string
     * @type {string}
     * @memberof Report
     */
    'prixm2ByPeriode': string;
    /**
     * Prixm2ByType json data as string
     * @type {string}
     * @memberof Report
     */
    'prixm2ByType': string;
    /**
     * Rentm2ByPeriode json data as string
     * @type {string}
     * @memberof Report
     */
    'rentm2ByPeriode'?: string;
    /**
     * Prixm2ByPeriode json data as string
     * @type {string}
     * @memberof Report
     */
    'ventesByPeriode': string;
    /**
     * Prixm2ByPeriode json data as string
     * @type {string}
     * @memberof Report
     */
    'demographyByPeriode': string;
    /**
     * Unique id (format UUID) of the Report
     * @type {string}
     * @memberof Report
     */
    'ID': string;
    /**
     * Type of the Report
     * @type {string}
     * @memberof Report
     */
    'type'?: ReportTypeEnum;
    /**
     * Status of the Report
     * @type {string}
     * @memberof Report
     */
    'status'?: ReportStatusEnum;
    /**
     * Created Product date (Format ISO 8601)
     * @type {string}
     * @memberof Report
     */
    'created_at'?: string;
    /**
     * Last update Product date (Format ISO 8601)
     * @type {string}
     * @memberof Report
     */
    'updated_at'?: string;
}

export const ReportTypeEnum = {
    Emplacement: 'EMPLACEMENT',
    Programme: 'PROGRAMME'
} as const;

export type ReportTypeEnum = typeof ReportTypeEnum[keyof typeof ReportTypeEnum];
export const ReportStatusEnum = {
    Enabled: 'ENABLED',
    Disabled: 'DISABLED'
} as const;

export type ReportStatusEnum = typeof ReportStatusEnum[keyof typeof ReportStatusEnum];

/**
 * 
 * @export
 * @interface ReportAllOf
 */
export interface ReportAllOf {
    /**
     * Unique id (format UUID) of the Report
     * @type {string}
     * @memberof ReportAllOf
     */
    'ID': string;
    /**
     * Type of the Report
     * @type {string}
     * @memberof ReportAllOf
     */
    'type'?: ReportAllOfTypeEnum;
    /**
     * Status of the Report
     * @type {string}
     * @memberof ReportAllOf
     */
    'status'?: ReportAllOfStatusEnum;
    /**
     * Created Product date (Format ISO 8601)
     * @type {string}
     * @memberof ReportAllOf
     */
    'created_at'?: string;
    /**
     * Last update Product date (Format ISO 8601)
     * @type {string}
     * @memberof ReportAllOf
     */
    'updated_at'?: string;
}

export const ReportAllOfTypeEnum = {
    Emplacement: 'EMPLACEMENT',
    Programme: 'PROGRAMME'
} as const;

export type ReportAllOfTypeEnum = typeof ReportAllOfTypeEnum[keyof typeof ReportAllOfTypeEnum];
export const ReportAllOfStatusEnum = {
    Enabled: 'ENABLED',
    Disabled: 'DISABLED'
} as const;

export type ReportAllOfStatusEnum = typeof ReportAllOfStatusEnum[keyof typeof ReportAllOfStatusEnum];

/**
 * 
 * @export
 * @interface ResponseListReport
 */
export interface ResponseListReport {
    /**
     * number of objects in the response
     * @type {number}
     * @memberof ResponseListReport
     */
    'count'?: number;
    /**
     * Users fetched
     * @type {Array<PartialReport>}
     * @memberof ResponseListReport
     */
    'reports'?: Array<PartialReport>;
}
/**
 * 
 * @export
 * @interface Sale
 */
export interface Sale {
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    'period'?: string;
    /**
     * 
     * @type {SaleVolumes}
     * @memberof Sale
     */
    'volumes'?: SaleVolumes;
    /**
     * 
     * @type {SaleNewLodgmentTrends}
     * @memberof Sale
     */
    'new_lodgment_trends'?: SaleNewLodgmentTrends;
    /**
     * 
     * @type {SaleRhythm}
     * @memberof Sale
     */
    'rhythm'?: SaleRhythm;
    /**
     * 
     * @type {SaleInvestments}
     * @memberof Sale
     */
    'investments'?: SaleInvestments;
}
/**
 * Investissements.
 * @export
 * @interface SaleInvestments
 */
export interface SaleInvestments {
    /**
     * Total d\'achats pour investissement.
     * @type {number}
     * @memberof SaleInvestments
     */
    'total_investor'?: number;
    /**
     * Taux d\'investisseurs.
     * @type {number}
     * @memberof SaleInvestments
     */
    'investor_rate'?: number;
    /**
     * Total d\'achats pour résidence.
     * @type {number}
     * @memberof SaleInvestments
     */
    'total_resident'?: number;
    /**
     * Taux résidents.
     * @type {number}
     * @memberof SaleInvestments
     */
    'resident_rate'?: number;
}
/**
 * Tendances du marché neuf.
 * @export
 * @interface SaleNewLodgmentTrends
 */
export interface SaleNewLodgmentTrends {
    /**
     * Prix m² moyen des ventes de logement neuf.
     * @type {number}
     * @memberof SaleNewLodgmentTrends
     */
    'price_per_meter_sales'?: number;
    /**
     * Taux de variation des ventes de logement neuf par rapport à l\'année précédente.
     * @type {number}
     * @memberof SaleNewLodgmentTrends
     */
    'sales_variation_rate'?: number;
    /**
     * Prix m² moyen des offres de logement neuf.
     * @type {number}
     * @memberof SaleNewLodgmentTrends
     */
    'price_per_meter_available_offer'?: number;
    /**
     * Taux de variation des offres de logement neuf par rapport à l\'année précédente.
     * @type {number}
     * @memberof SaleNewLodgmentTrends
     */
    'available_offer_variation_rate'?: number;
}
/**
 * Ecoulement des lots.
 * @export
 * @interface SaleRhythm
 */
export interface SaleRhythm {
    /**
     * Taux de variation de la vitesse d\'écoulement des logements par rapport à l\'année précédente.
     * @type {number}
     * @memberof SaleRhythm
     */
    'rhythm_variation_rate'?: number;
    /**
     * Vitesse moyenne d\'écoulement des lots (mois).
     * @type {number}
     * @memberof SaleRhythm
     */
    'average_rhythm'?: number;
}
/**
 * Volumes de vente.
 * @export
 * @interface SaleVolumes
 */
export interface SaleVolumes {
    /**
     * Total des programmes disponnibles.
     * @type {number}
     * @memberof SaleVolumes
     */
    'total_programs'?: number;
    /**
     * Total des logements à la vente.
     * @type {number}
     * @memberof SaleVolumes
     */
    'available_offer'?: number;
    /**
     * Nombre de logements arrivés sur le marché.
     * @type {number}
     * @memberof SaleVolumes
     */
    'commercialized'?: number;
    /**
     * Nombre de logements vendus.
     * @type {number}
     * @memberof SaleVolumes
     */
    'sold'?: number;
}
/**
 * Division des personnes seules.
 * @export
 * @interface SinglePersonDivision
 */
export interface SinglePersonDivision {
    /**
     * Homme.
     * @type {number}
     * @memberof SinglePersonDivision
     */
    'man'?: number;
    /**
     * Femme.
     * @type {number}
     * @memberof SinglePersonDivision
     */
    'woman'?: number;
}
/**
 * 
 * @export
 * @interface UpdateReport
 */
export interface UpdateReport {
    /**
     * Program json data as string
     * @type {string}
     * @memberof UpdateReport
     */
    'program'?: string;
    /**
     * Report comments json data as string
     * @type {string}
     * @memberof UpdateReport
     */
    'comments'?: string;
    /**
     * DeveloperPrograms json data as string
     * @type {string}
     * @memberof UpdateReport
     */
    'developerPrograms'?: string;
    /**
     * EcosystemPrograms json data as string
     * @type {string}
     * @memberof UpdateReport
     */
    'ecosystemPrograms'?: string;
    /**
     * Prixm2ByPeriode json data as string
     * @type {string}
     * @memberof UpdateReport
     */
    'prixm2ByPeriode': string;
    /**
     * Prixm2ByType json data as string
     * @type {string}
     * @memberof UpdateReport
     */
    'prixm2ByType': string;
    /**
     * Prixm2ByPeriode json data as string
     * @type {string}
     * @memberof UpdateReport
     */
    'ventesByPeriode': string;
    /**
     * Prixm2ByPeriode json data as string
     * @type {string}
     * @memberof UpdateReport
     */
    'demographyByPeriode': string;
}

/**
 * AttractivenessApi - axios parameter creator
 * @export
 */
export const AttractivenessApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Rechercher les indicateurs d\'évolution des prix m² par période dans une zone définie.
         * @summary Rechercher les indicateurs d\'évolution des prix m² par période.
         * @param {'year' | 'quarter'} [frequency] Type d\&#39;occurence antérieures à scanner.
         * @param {number} [nbOccurence] Nombre d\&#39;occurences antérieures à scanner.
         * @param {Array<GeoDistances>} [geoDistances] 
         * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPrixm2ByPeriode: async (frequency?: 'year' | 'quarter', nbOccurence?: number, geoDistances?: Array<GeoDistances>, geoBoundingBox?: Array<GeoBoundingBox>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/attractiveness/price-meter/periode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (frequency !== undefined) {
                localVarQueryParameter['frequency'] = frequency;
            }

            if (nbOccurence !== undefined) {
                localVarQueryParameter['nb_occurence'] = nbOccurence;
            }

            if (geoDistances) {
                localVarQueryParameter['geo_distances'] = geoDistances;
            }

            if (geoBoundingBox) {
                localVarQueryParameter['geo_bounding_box'] = geoBoundingBox;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Rechercher les indicateurs d\'évolution des prix m² par type de logement dans une zone définie.
         * @summary Rechercher les indicateurs d\'évolution des prix m² par type de logement.
         * @param {'year' | 'quarter'} [frequency] Type d\&#39;occurence antérieures à scanner.
         * @param {number} [nbOccurence] Nombre d\&#39;occurences antérieures à scanner.
         * @param {Array<GeoDistances>} [geoDistances] 
         * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPrixm2ByType: async (frequency?: 'year' | 'quarter', nbOccurence?: number, geoDistances?: Array<GeoDistances>, geoBoundingBox?: Array<GeoBoundingBox>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/attractiveness/price-meter/type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (frequency !== undefined) {
                localVarQueryParameter['frequency'] = frequency;
            }

            if (nbOccurence !== undefined) {
                localVarQueryParameter['nb_occurence'] = nbOccurence;
            }

            if (geoDistances) {
                localVarQueryParameter['geo_distances'] = geoDistances;
            }

            if (geoBoundingBox) {
                localVarQueryParameter['geo_bounding_box'] = geoBoundingBox;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AttractivenessApi - functional programming interface
 * @export
 */
export const AttractivenessApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AttractivenessApiAxiosParamCreator(configuration)
    return {
        /**
         * Rechercher les indicateurs d\'évolution des prix m² par période dans une zone définie.
         * @summary Rechercher les indicateurs d\'évolution des prix m² par période.
         * @param {'year' | 'quarter'} [frequency] Type d\&#39;occurence antérieures à scanner.
         * @param {number} [nbOccurence] Nombre d\&#39;occurences antérieures à scanner.
         * @param {Array<GeoDistances>} [geoDistances] 
         * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPrixm2ByPeriode(frequency?: 'year' | 'quarter', nbOccurence?: number, geoDistances?: Array<GeoDistances>, geoBoundingBox?: Array<GeoBoundingBox>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListPriceMeterPeriod>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPrixm2ByPeriode(frequency, nbOccurence, geoDistances, geoBoundingBox, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Rechercher les indicateurs d\'évolution des prix m² par type de logement dans une zone définie.
         * @summary Rechercher les indicateurs d\'évolution des prix m² par type de logement.
         * @param {'year' | 'quarter'} [frequency] Type d\&#39;occurence antérieures à scanner.
         * @param {number} [nbOccurence] Nombre d\&#39;occurences antérieures à scanner.
         * @param {Array<GeoDistances>} [geoDistances] 
         * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPrixm2ByType(frequency?: 'year' | 'quarter', nbOccurence?: number, geoDistances?: Array<GeoDistances>, geoBoundingBox?: Array<GeoBoundingBox>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListPriceMeterType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPrixm2ByType(frequency, nbOccurence, geoDistances, geoBoundingBox, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AttractivenessApi - factory interface
 * @export
 */
export const AttractivenessApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AttractivenessApiFp(configuration)
    return {
        /**
         * Rechercher les indicateurs d\'évolution des prix m² par période dans une zone définie.
         * @summary Rechercher les indicateurs d\'évolution des prix m² par période.
         * @param {'year' | 'quarter'} [frequency] Type d\&#39;occurence antérieures à scanner.
         * @param {number} [nbOccurence] Nombre d\&#39;occurences antérieures à scanner.
         * @param {Array<GeoDistances>} [geoDistances] 
         * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPrixm2ByPeriode(frequency?: 'year' | 'quarter', nbOccurence?: number, geoDistances?: Array<GeoDistances>, geoBoundingBox?: Array<GeoBoundingBox>, options?: any): AxiosPromise<Array<ListPriceMeterPeriod>> {
            return localVarFp.listPrixm2ByPeriode(frequency, nbOccurence, geoDistances, geoBoundingBox, options).then((request) => request(axios, basePath));
        },
        /**
         * Rechercher les indicateurs d\'évolution des prix m² par type de logement dans une zone définie.
         * @summary Rechercher les indicateurs d\'évolution des prix m² par type de logement.
         * @param {'year' | 'quarter'} [frequency] Type d\&#39;occurence antérieures à scanner.
         * @param {number} [nbOccurence] Nombre d\&#39;occurences antérieures à scanner.
         * @param {Array<GeoDistances>} [geoDistances] 
         * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPrixm2ByType(frequency?: 'year' | 'quarter', nbOccurence?: number, geoDistances?: Array<GeoDistances>, geoBoundingBox?: Array<GeoBoundingBox>, options?: any): AxiosPromise<Array<ListPriceMeterType>> {
            return localVarFp.listPrixm2ByType(frequency, nbOccurence, geoDistances, geoBoundingBox, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AttractivenessApi - object-oriented interface
 * @export
 * @class AttractivenessApi
 * @extends {BaseAPI}
 */
export class AttractivenessApi extends BaseAPI {
    /**
     * Rechercher les indicateurs d\'évolution des prix m² par période dans une zone définie.
     * @summary Rechercher les indicateurs d\'évolution des prix m² par période.
     * @param {'year' | 'quarter'} [frequency] Type d\&#39;occurence antérieures à scanner.
     * @param {number} [nbOccurence] Nombre d\&#39;occurences antérieures à scanner.
     * @param {Array<GeoDistances>} [geoDistances] 
     * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttractivenessApi
     */
    public listPrixm2ByPeriode(frequency?: 'year' | 'quarter', nbOccurence?: number, geoDistances?: Array<GeoDistances>, geoBoundingBox?: Array<GeoBoundingBox>, options?: AxiosRequestConfig) {
        return AttractivenessApiFp(this.configuration).listPrixm2ByPeriode(frequency, nbOccurence, geoDistances, geoBoundingBox, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Rechercher les indicateurs d\'évolution des prix m² par type de logement dans une zone définie.
     * @summary Rechercher les indicateurs d\'évolution des prix m² par type de logement.
     * @param {'year' | 'quarter'} [frequency] Type d\&#39;occurence antérieures à scanner.
     * @param {number} [nbOccurence] Nombre d\&#39;occurences antérieures à scanner.
     * @param {Array<GeoDistances>} [geoDistances] 
     * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttractivenessApi
     */
    public listPrixm2ByType(frequency?: 'year' | 'quarter', nbOccurence?: number, geoDistances?: Array<GeoDistances>, geoBoundingBox?: Array<GeoBoundingBox>, options?: AxiosRequestConfig) {
        return AttractivenessApiFp(this.configuration).listPrixm2ByType(frequency, nbOccurence, geoDistances, geoBoundingBox, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommerceApi - axios parameter creator
 * @export
 */
export const CommerceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Rechercher les indicateurs des ventes par période dans une zone définie.
         * @summary Rechercher les indicateurs des ventes par période.
         * @param {'year' | 'quarter'} [frequency] Type d\&#39;occurence antérieures à scanner.
         * @param {number} [nbOccurence] Nombre d\&#39;occurences antérieures à scanner.
         * @param {Array<GeoDistances>} [geoDistances] 
         * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVentesByPeriode: async (frequency?: 'year' | 'quarter', nbOccurence?: number, geoDistances?: Array<GeoDistances>, geoBoundingBox?: Array<GeoBoundingBox>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/commerce/sales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (frequency !== undefined) {
                localVarQueryParameter['frequency'] = frequency;
            }

            if (nbOccurence !== undefined) {
                localVarQueryParameter['nb_occurence'] = nbOccurence;
            }

            if (geoDistances) {
                localVarQueryParameter['geo_distances'] = geoDistances;
            }

            if (geoBoundingBox) {
                localVarQueryParameter['geo_bounding_box'] = geoBoundingBox;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommerceApi - functional programming interface
 * @export
 */
export const CommerceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommerceApiAxiosParamCreator(configuration)
    return {
        /**
         * Rechercher les indicateurs des ventes par période dans une zone définie.
         * @summary Rechercher les indicateurs des ventes par période.
         * @param {'year' | 'quarter'} [frequency] Type d\&#39;occurence antérieures à scanner.
         * @param {number} [nbOccurence] Nombre d\&#39;occurences antérieures à scanner.
         * @param {Array<GeoDistances>} [geoDistances] 
         * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listVentesByPeriode(frequency?: 'year' | 'quarter', nbOccurence?: number, geoDistances?: Array<GeoDistances>, geoBoundingBox?: Array<GeoBoundingBox>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListSales>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listVentesByPeriode(frequency, nbOccurence, geoDistances, geoBoundingBox, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommerceApi - factory interface
 * @export
 */
export const CommerceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommerceApiFp(configuration)
    return {
        /**
         * Rechercher les indicateurs des ventes par période dans une zone définie.
         * @summary Rechercher les indicateurs des ventes par période.
         * @param {'year' | 'quarter'} [frequency] Type d\&#39;occurence antérieures à scanner.
         * @param {number} [nbOccurence] Nombre d\&#39;occurences antérieures à scanner.
         * @param {Array<GeoDistances>} [geoDistances] 
         * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVentesByPeriode(frequency?: 'year' | 'quarter', nbOccurence?: number, geoDistances?: Array<GeoDistances>, geoBoundingBox?: Array<GeoBoundingBox>, options?: any): AxiosPromise<Array<ListSales>> {
            return localVarFp.listVentesByPeriode(frequency, nbOccurence, geoDistances, geoBoundingBox, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommerceApi - object-oriented interface
 * @export
 * @class CommerceApi
 * @extends {BaseAPI}
 */
export class CommerceApi extends BaseAPI {
    /**
     * Rechercher les indicateurs des ventes par période dans une zone définie.
     * @summary Rechercher les indicateurs des ventes par période.
     * @param {'year' | 'quarter'} [frequency] Type d\&#39;occurence antérieures à scanner.
     * @param {number} [nbOccurence] Nombre d\&#39;occurences antérieures à scanner.
     * @param {Array<GeoDistances>} [geoDistances] 
     * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommerceApi
     */
    public listVentesByPeriode(frequency?: 'year' | 'quarter', nbOccurence?: number, geoDistances?: Array<GeoDistances>, geoBoundingBox?: Array<GeoBoundingBox>, options?: AxiosRequestConfig) {
        return CommerceApiFp(this.configuration).listVentesByPeriode(frequency, nbOccurence, geoDistances, geoBoundingBox, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthcheckApi - axios parameter creator
 * @export
 */
export const HealthcheckApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The healthcheck endpoint retrieve 200 code if service is up.
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/healthcheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthcheckApi - functional programming interface
 * @export
 */
export const HealthcheckApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthcheckApiAxiosParamCreator(configuration)
    return {
        /**
         * The healthcheck endpoint retrieve 200 code if service is up.
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthcheckApi - factory interface
 * @export
 */
export const HealthcheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthcheckApiFp(configuration)
    return {
        /**
         * The healthcheck endpoint retrieve 200 code if service is up.
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth(options?: any): AxiosPromise<void> {
            return localVarFp.getHealth(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthcheckApi - object-oriented interface
 * @export
 * @class HealthcheckApi
 * @extends {BaseAPI}
 */
export class HealthcheckApi extends BaseAPI {
    /**
     * The healthcheck endpoint retrieve 200 code if service is up.
     * @summary Health Check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthcheckApi
     */
    public getHealth(options?: AxiosRequestConfig) {
        return HealthcheckApiFp(this.configuration).getHealth(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InseeApi - axios parameter creator
 * @export
 */
export const InseeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Rechercher les indicateurs démographiques par période dans une zone géographique.
         * @summary Rechercher les indicateurs démographiques par période.
         * @param {number} [nbYears] Nombre d\&#39;années antérieures à scanner.
         * @param {Array<GeoDistances>} [geoDistances] 
         * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemographyByPeriode: async (nbYears?: number, geoDistances?: Array<GeoDistances>, geoBoundingBox?: Array<GeoBoundingBox>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/insee/demography`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nbYears !== undefined) {
                localVarQueryParameter['nb_years'] = nbYears;
            }

            if (geoDistances) {
                localVarQueryParameter['geo_distances'] = geoDistances;
            }

            if (geoBoundingBox) {
                localVarQueryParameter['geo_bounding_box'] = geoBoundingBox;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InseeApi - functional programming interface
 * @export
 */
export const InseeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InseeApiAxiosParamCreator(configuration)
    return {
        /**
         * Rechercher les indicateurs démographiques par période dans une zone géographique.
         * @summary Rechercher les indicateurs démographiques par période.
         * @param {number} [nbYears] Nombre d\&#39;années antérieures à scanner.
         * @param {Array<GeoDistances>} [geoDistances] 
         * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDemographyByPeriode(nbYears?: number, geoDistances?: Array<GeoDistances>, geoBoundingBox?: Array<GeoBoundingBox>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListDemography>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDemographyByPeriode(nbYears, geoDistances, geoBoundingBox, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InseeApi - factory interface
 * @export
 */
export const InseeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InseeApiFp(configuration)
    return {
        /**
         * Rechercher les indicateurs démographiques par période dans une zone géographique.
         * @summary Rechercher les indicateurs démographiques par période.
         * @param {number} [nbYears] Nombre d\&#39;années antérieures à scanner.
         * @param {Array<GeoDistances>} [geoDistances] 
         * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDemographyByPeriode(nbYears?: number, geoDistances?: Array<GeoDistances>, geoBoundingBox?: Array<GeoBoundingBox>, options?: any): AxiosPromise<Array<ListDemography>> {
            return localVarFp.listDemographyByPeriode(nbYears, geoDistances, geoBoundingBox, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InseeApi - object-oriented interface
 * @export
 * @class InseeApi
 * @extends {BaseAPI}
 */
export class InseeApi extends BaseAPI {
    /**
     * Rechercher les indicateurs démographiques par période dans une zone géographique.
     * @summary Rechercher les indicateurs démographiques par période.
     * @param {number} [nbYears] Nombre d\&#39;années antérieures à scanner.
     * @param {Array<GeoDistances>} [geoDistances] 
     * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InseeApi
     */
    public listDemographyByPeriode(nbYears?: number, geoDistances?: Array<GeoDistances>, geoBoundingBox?: Array<GeoBoundingBox>, options?: AxiosRequestConfig) {
        return InseeApiFp(this.configuration).listDemographyByPeriode(nbYears, geoDistances, geoBoundingBox, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MetricsApi - axios parameter creator
 * @export
 */
export const MetricsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The Metrics endpoint retrieves stats data from http request logs.
         * @summary Fetch Metrics
         * @param {string} subscriptionId ID (uuid) of subscription
         * @param {number} [nbDays] Nombre de jours antérieures à scanner.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetrics: async (subscriptionId: string, nbDays?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            assertParamExists('getMetrics', 'subscriptionId', subscriptionId)
            const localVarPath = `/metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nbDays !== undefined) {
                localVarQueryParameter['nb_days'] = nbDays;
            }

            if (subscriptionId !== undefined) {
                localVarQueryParameter['subscription_id'] = subscriptionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetricsApi - functional programming interface
 * @export
 */
export const MetricsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetricsApiAxiosParamCreator(configuration)
    return {
        /**
         * The Metrics endpoint retrieves stats data from http request logs.
         * @summary Fetch Metrics
         * @param {string} subscriptionId ID (uuid) of subscription
         * @param {number} [nbDays] Nombre de jours antérieures à scanner.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetrics(subscriptionId: string, nbDays?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Metric>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetrics(subscriptionId, nbDays, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MetricsApi - factory interface
 * @export
 */
export const MetricsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetricsApiFp(configuration)
    return {
        /**
         * The Metrics endpoint retrieves stats data from http request logs.
         * @summary Fetch Metrics
         * @param {string} subscriptionId ID (uuid) of subscription
         * @param {number} [nbDays] Nombre de jours antérieures à scanner.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetrics(subscriptionId: string, nbDays?: number, options?: any): AxiosPromise<Array<Metric>> {
            return localVarFp.getMetrics(subscriptionId, nbDays, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetricsApi - object-oriented interface
 * @export
 * @class MetricsApi
 * @extends {BaseAPI}
 */
export class MetricsApi extends BaseAPI {
    /**
     * The Metrics endpoint retrieves stats data from http request logs.
     * @summary Fetch Metrics
     * @param {string} subscriptionId ID (uuid) of subscription
     * @param {number} [nbDays] Nombre de jours antérieures à scanner.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public getMetrics(subscriptionId: string, nbDays?: number, options?: AxiosRequestConfig) {
        return MetricsApiFp(this.configuration).getMetrics(subscriptionId, nbDays, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PromotersApi - axios parameter creator
 * @export
 */
export const PromotersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Rechercher des promoteurs immobilier.
         * @summary Rechercher des promoteurs.
         * @param {number} [size] 
         * @param {string} [textQuery] Text like query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPromoters: async (size?: number, textQuery?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/promoters/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (textQuery !== undefined) {
                localVarQueryParameter['text_query'] = textQuery;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromotersApi - functional programming interface
 * @export
 */
export const PromotersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PromotersApiAxiosParamCreator(configuration)
    return {
        /**
         * Rechercher des promoteurs immobilier.
         * @summary Rechercher des promoteurs.
         * @param {number} [size] 
         * @param {string} [textQuery] Text like query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPromoters(size?: number, textQuery?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPromoters>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPromoters(size, textQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PromotersApi - factory interface
 * @export
 */
export const PromotersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PromotersApiFp(configuration)
    return {
        /**
         * Rechercher des promoteurs immobilier.
         * @summary Rechercher des promoteurs.
         * @param {number} [size] 
         * @param {string} [textQuery] Text like query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPromoters(size?: number, textQuery?: string, options?: any): AxiosPromise<ListPromoters> {
            return localVarFp.listPromoters(size, textQuery, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PromotersApi - object-oriented interface
 * @export
 * @class PromotersApi
 * @extends {BaseAPI}
 */
export class PromotersApi extends BaseAPI {
    /**
     * Rechercher des promoteurs immobilier.
     * @summary Rechercher des promoteurs.
     * @param {number} [size] 
     * @param {string} [textQuery] Text like query.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotersApi
     */
    public listPromoters(size?: number, textQuery?: string, options?: AxiosRequestConfig) {
        return PromotersApiFp(this.configuration).listPromoters(size, textQuery, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Créer un rapport d\'emplacement ou de programme.
         * @summary Créer un rapport.
         * @param {NewReport} newReport Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport: async (newReport: NewReport, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newReport' is not null or undefined
            assertParamExists('createReport', 'newReport', newReport)
            const localVarPath = `/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newReport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer un rapport par son unique ID.
         * @summary Supprimer un rapport par ID.
         * @param {string} id ID (uuid) of Report to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableReportByID: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('disableReportByID', 'id', id)
            const localVarPath = `/reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Trouver un rapport par son unique ID.
         * @summary Trouver un rapport par ID.
         * @param {string} id ID (uuid) of the Report to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReportByID: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findReportByID', 'id', id)
            const localVarPath = `/reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lister les rapports d\'un utilisateur.
         * @summary Lister les rapports.
         * @param {string} [groupId] ID de regroupement des rapports (ref, uuid, etc..)
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReports: async (groupId?: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (groupId !== undefined) {
                localVarQueryParameter['group_id'] = groupId;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (sortDirDesc !== undefined) {
                localVarQueryParameter['sort_dir_desc'] = sortDirDesc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour un rapport par son unique ID.
         * @summary Mettre à jour un rapport
         * @param {string} id ID (uuid) of the Report to update
         * @param {UpdateReport} updateReport Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReport: async (id: string, updateReport: UpdateReport, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateReport', 'id', id)
            // verify required parameter 'updateReport' is not null or undefined
            assertParamExists('updateReport', 'updateReport', updateReport)
            const localVarPath = `/reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateReport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * Créer un rapport d\'emplacement ou de programme.
         * @summary Créer un rapport.
         * @param {NewReport} newReport Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReport(newReport: NewReport, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReport(newReport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Supprimer un rapport par son unique ID.
         * @summary Supprimer un rapport par ID.
         * @param {string} id ID (uuid) of Report to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disableReportByID(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disableReportByID(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Trouver un rapport par son unique ID.
         * @summary Trouver un rapport par ID.
         * @param {string} id ID (uuid) of the Report to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findReportByID(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findReportByID(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lister les rapports d\'un utilisateur.
         * @summary Lister les rapports.
         * @param {string} [groupId] ID de regroupement des rapports (ref, uuid, etc..)
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findReports(groupId?: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResponseListReport>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findReports(groupId, orderBy, pageSize, pageToken, sortDirDesc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Mettre à jour un rapport par son unique ID.
         * @summary Mettre à jour un rapport
         * @param {string} id ID (uuid) of the Report to update
         * @param {UpdateReport} updateReport Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReport(id: string, updateReport: UpdateReport, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReport(id, updateReport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * Créer un rapport d\'emplacement ou de programme.
         * @summary Créer un rapport.
         * @param {NewReport} newReport Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport(newReport: NewReport, options?: any): AxiosPromise<Report> {
            return localVarFp.createReport(newReport, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer un rapport par son unique ID.
         * @summary Supprimer un rapport par ID.
         * @param {string} id ID (uuid) of Report to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableReportByID(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.disableReportByID(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Trouver un rapport par son unique ID.
         * @summary Trouver un rapport par ID.
         * @param {string} id ID (uuid) of the Report to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReportByID(id: string, options?: any): AxiosPromise<Report> {
            return localVarFp.findReportByID(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Lister les rapports d\'un utilisateur.
         * @summary Lister les rapports.
         * @param {string} [groupId] ID de regroupement des rapports (ref, uuid, etc..)
         * @param {string} [orderBy] order by field
         * @param {number} [pageSize] pagination size
         * @param {number} [pageToken] Page index token (starting from 0)
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReports(groupId?: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: any): AxiosPromise<Array<ResponseListReport>> {
            return localVarFp.findReports(groupId, orderBy, pageSize, pageToken, sortDirDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour un rapport par son unique ID.
         * @summary Mettre à jour un rapport
         * @param {string} id ID (uuid) of the Report to update
         * @param {UpdateReport} updateReport Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReport(id: string, updateReport: UpdateReport, options?: any): AxiosPromise<Report> {
            return localVarFp.updateReport(id, updateReport, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * Créer un rapport d\'emplacement ou de programme.
     * @summary Créer un rapport.
     * @param {NewReport} newReport Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public createReport(newReport: NewReport, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).createReport(newReport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer un rapport par son unique ID.
     * @summary Supprimer un rapport par ID.
     * @param {string} id ID (uuid) of Report to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public disableReportByID(id: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).disableReportByID(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Trouver un rapport par son unique ID.
     * @summary Trouver un rapport par ID.
     * @param {string} id ID (uuid) of the Report to fetch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public findReportByID(id: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).findReportByID(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lister les rapports d\'un utilisateur.
     * @summary Lister les rapports.
     * @param {string} [groupId] ID de regroupement des rapports (ref, uuid, etc..)
     * @param {string} [orderBy] order by field
     * @param {number} [pageSize] pagination size
     * @param {number} [pageToken] Page index token (starting from 0)
     * @param {boolean} [sortDirDesc] is sorting desc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public findReports(groupId?: string, orderBy?: string, pageSize?: number, pageToken?: number, sortDirDesc?: boolean, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).findReports(groupId, orderBy, pageSize, pageToken, sortDirDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour un rapport par son unique ID.
     * @summary Mettre à jour un rapport
     * @param {string} id ID (uuid) of the Report to update
     * @param {UpdateReport} updateReport Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public updateReport(id: string, updateReport: UpdateReport, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).updateReport(id, updateReport, options).then((request) => request(this.axios, this.basePath));
    }
}


