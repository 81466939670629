import { ref } from "@vue/composition-api";
import store from "@/store";
import { useRouter } from "@core/utils/utils";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export const useDecimmoReport = () => {
  const { router } = useRouter();
  // Use toast
  const toast = useToast();

  const reportData = ref({
    isBusy: false,
    // Report datas
    program: {},
    ecosystemPrograms: [],
    developerPrograms: [],
    rentTrend: [],
    prixm2ByPeriode: [],
    prixm2ByType: [],
    ventesByPeriode: [],
    demographyByPeriode: [],
    // Comments datas
    comments: {
      squareMetersTrend: { value: "", enable: false },
      squareMetersType: { value: "", enable: false },
      programsEcosystem: { value: "", enable: false },
      rentalPinelYield: { value: "", enable: false },
      programsDeveloper: { value: "", enable: false },
      rentTrend: { value: "", enable: false },
      rentalYieldTrend: { value: "", enable: false },
      settleSurvey: { value: "", enable: false },
      salesVolume: { value: "", enable: false },
      salesTrend: { value: "", enable: false },
      salesSpeed: { value: "", enable: false },
      salesInvest: { value: "", enable: false },
      buildingTimeDivision: { value: "", enable: false },
      householdDivision: { value: "", enable: false },
      lastYearRotation: { value: "", enable: false },
      logementType: { value: "", enable: false },
      mainResidenceType: { value: "", enable: false },
      moveInAge: { value: "", enable: false },
      employments: { value: "", enable: false },
      employmentDivision: { value: "", enable: false },
      populationTrend: { value: "", enable: false },
      peopleDivision: { value: "", enable: false },
    },
  });

  const handleError = () => {
    reportData.value.isBusy = false;
    toast(
      {
        component: ToastificationContent,
        props: {
          title: "Services 'Cadre de Vie' indisponible",
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      },
      { position: "top-center" }
    );
  };

  const saveReportData = () => {
    const newReport = {
      name: reportData.value.program.name,
      group_id: store.state.auth.userProfile.ID,
      latitude: Number(reportData.value.program.location.latitude),
      longitude: Number(reportData.value.program.location.longitude),
      program: JSON.stringify(reportData.value.program),
      comments: JSON.stringify(reportData.value.comments),
      ecosystemPrograms: JSON.stringify(reportData.value.ecosystemPrograms),
      developerPrograms: JSON.stringify(reportData.value.developerPrograms),
      rentm2ByPeriode: JSON.stringify(reportData.value.rentTrend),
      prixm2ByPeriode: JSON.stringify(reportData.value.prixm2ByPeriode),
      prixm2ByType: JSON.stringify(reportData.value.prixm2ByType),
      ventesByPeriode: JSON.stringify(reportData.value.ventesByPeriode),
      demographyByPeriode: JSON.stringify(reportData.value.demographyByPeriode),
    };

    store
      .dispatch("app-decimmo/createReport", newReport)
      .then((response) => {
        reportData.value.isBusy = false;
        toast(
          {
            component: ToastificationContent,
            props: {
              title: "Rapport enregistré avec succès",
              icon: "CheckCircleIcon",
              variant: "success",
            },
          },
          { position: "top-center" }
        );
        router.push({
          name: "decimmo-report",
          query: { id: response.data.ID },
        });
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const saveReportPositionData = (lat, lng) => {
    const newReport = {
      name: reportData.value.demographyByPeriode[0].sector_name,
      group_id: store.state.auth.userProfile.ID,
      latitude: Number(lat),
      longitude: Number(lng),
      comments: JSON.stringify(reportData.value.comments),
      ecosystemPrograms: JSON.stringify(reportData.value.ecosystemPrograms),
      prixm2ByPeriode: JSON.stringify(reportData.value.prixm2ByPeriode),
      rentm2ByPeriode: JSON.stringify(reportData.value.rentTrend),
      prixm2ByType: JSON.stringify(reportData.value.prixm2ByType),
      ventesByPeriode: JSON.stringify(reportData.value.ventesByPeriode),
      demographyByPeriode: JSON.stringify(reportData.value.demographyByPeriode),
    };

    store
      .dispatch("app-decimmo/createReport", newReport)
      .then((response) => {
        reportData.value.isBusy = false;
        toast(
          {
            component: ToastificationContent,
            props: {
              title: "Rapport enregistré avec succès",
              icon: "CheckCircleIcon",
              variant: "success",
            },
          },
          { position: "top-center" }
        );
        router.push({
          name: "decimmo-report-position",
          query: { id: response.data.ID },
        });
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const fetchReportData = (programID) => {
    reportData.value.isBusy = true;
    store
      .dispatch("app-decimmo/getProgramByID", programID)
      .then((response) => {
        const program = response.data;
        const center = {
          latitude: Number(program.location.latitude),
          longitude: Number(program.location.longitude),
        };

        reportData.value.program = program;

        const searchListRentEvolution = {
          nbYears: 5,
          zipCode: program.location.zip,
        };

        const searchYearsGeo = {
          nb_years: 4,
          geo_distances: [
            JSON.stringify({
              distance: 0.01,
              lat: Number(program.location.latitude),
              lng: Number(program.location.longitude),
            }),
          ],
        };

        const searchTempoGeo = {
          frequency: "quarter",
          nb_occurence: 6,
          geo_distances: [
            JSON.stringify({
              distance: 0.01,
              lat: Number(program.location.latitude),
              lng: Number(program.location.longitude),
            }),
          ],
        };

        const searchListPrograms = {
          page: 1,
          page_limit: 7,
          geometries: {
            circle: {
              distance: 3500, // convert in meters
              center,
            },
          },
          filters: {
            key_value: [],
            range: [],
          },
        };

        const searchListProgramsDeveloper = {
          page: 1,
          page_limit: 7,
          geometries: {
            circle: {
              distance: 10000, // convert in meters
              center,
            },
          },
          filters: {
            key_value: [
              {
                key: "promoter_name",
                value: program.promoter_name,
              },
            ],
            range: [],
          },
        };

        const promiseDeveloperPrograms = new Promise((resolve, reject) => {
          store
            .dispatch("app-decimmo/listPrograms", searchListProgramsDeveloper)
            .then((response) => {
              reportData.value.developerPrograms = response.data.programs
                .filter((item) => item.name !== program.name)
                .sort(function (a, b) {
                  return b.total_available_lots - a.total_available_lots;
                });
              resolve();
            })
            .catch(() => reject());
        });

        const promiseRentEvolution = new Promise((resolve, reject) => {
          store
            .dispatch(
              "app-decimmo/fetchRentEstimation",
              searchListRentEvolution
            )
            .then((response) => {
              reportData.value.rentTrend = response.data;
              resolve();
            })
            .catch(() => reject());
        });

        const promiseEcosystemPrograms = new Promise((resolve, reject) => {
          store
            .dispatch("app-decimmo/listPrograms", searchListPrograms)
            .then((response) => {
              reportData.value.ecosystemPrograms = response.data.programs
                .filter((item) => item.name !== program.name)
                .sort(function (a, b) {
                  return b.total_available_lots - a.total_available_lots;
                });
              resolve();
            })
            .catch(() => reject());
        });

        const promiseListPrixm2ByPeriode = new Promise((resolve, reject) => {
          store
            .dispatch("app-decimmo/listPrixm2ByPeriode", searchTempoGeo)
            .then((response) => {
              reportData.value.prixm2ByPeriode = response.data;
              resolve();
            })
            .catch(() => reject());
        });

        const promiseListPrixm2ByType = new Promise((resolve, reject) => {
          store
            .dispatch("app-decimmo/listPrixm2ByType", searchTempoGeo)
            .then((response) => {
              reportData.value.prixm2ByType = response.data;
              resolve();
            })
            .catch(() => reject());
        });

        const promiseListVentesByPeriode = new Promise((resolve, reject) => {
          store
            .dispatch("app-decimmo/listVentesByPeriode", searchTempoGeo)
            .then((response) => {
              reportData.value.ventesByPeriode = response.data;
              resolve();
            })
            .catch(() => reject());
        });

        const promiseDemographyByPeriode = new Promise((resolve, reject) => {
          store
            .dispatch("app-decimmo/listDemographyByPeriode", searchYearsGeo)
            .then((response) => {
              reportData.value.demographyByPeriode = response.data;
              resolve();
            })
            .catch(() => reject());
        });

        Promise.all([
          promiseRentEvolution,
          promiseDeveloperPrograms,
          promiseEcosystemPrograms,
          promiseListPrixm2ByPeriode,
          promiseListPrixm2ByType,
          promiseListVentesByPeriode,
          promiseDemographyByPeriode,
        ])
          .then(() => {
            saveReportData();
          })
          .catch(() => {
            saveReportData();
          });
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const fetchReportPositionData = (lat, lng, zip) => {
    reportData.value.isBusy = true;
    const center = {
      latitude: Number(lat),
      longitude: Number(lng),
    };
    reportData.value.center = center;

    const searchListRentEvolution = {
      nbYears: 5,
      zipCode: zip,
    };

    const searchYearsGeo = {
      nb_years: 4,
      geo_distances: [
        JSON.stringify({
          distance: 0.01,
          lat: Number(lat),
          lng: Number(lng),
        }),
      ],
    };

    const searchTempoGeo = {
      frequency: "quarter",
      nb_occurence: 6,
      geo_distances: [
        JSON.stringify({
          distance: 0.01,
          lat: Number(lat),
          lng: Number(lng),
        }),
      ],
    };

    const searchListPrograms = {
      page: 1,
      page_limit: 7,
      geometries: {
        circle: {
          distance: 3500, // convert in meters
          center,
        },
      },
      filters: {
        key_value: [],
        range: [],
      },
    };

    const promiseEcosystemPrograms = new Promise((resolve, reject) => {
      store
        .dispatch("app-decimmo/listPrograms", searchListPrograms)
        .then((response) => {
          reportData.value.ecosystemPrograms = response.data.programs
            ? response.data.programs.sort(function (a, b) {
                return b.total_available_lots - a.total_available_lots;
              })
            : [];
          resolve();
        })
        .catch(() => reject());
    });

    const promiseRentEvolution = new Promise((resolve, reject) => {
      store
        .dispatch("app-decimmo/fetchRentEstimation", searchListRentEvolution)
        .then((response) => {
          reportData.value.rentTrend = response.data;
          resolve();
        })
        .catch(() => reject());
    });

    const promiseListPrixm2ByPeriode = new Promise((resolve, reject) => {
      store
        .dispatch("app-decimmo/listPrixm2ByPeriode", searchTempoGeo)
        .then((response) => {
          reportData.value.prixm2ByPeriode = response.data;
          resolve();
        })
        .catch(() => reject());
    });

    const promiseListPrixm2ByType = new Promise((resolve, reject) => {
      store
        .dispatch("app-decimmo/listPrixm2ByType", searchTempoGeo)
        .then((response) => {
          reportData.value.prixm2ByType = response.data;
          resolve();
        })
        .catch(() => reject());
    });

    const promiseListVentesByPeriode = new Promise((resolve, reject) => {
      store
        .dispatch("app-decimmo/listVentesByPeriode", searchTempoGeo)
        .then((response) => {
          reportData.value.ventesByPeriode = response.data;
          resolve();
        })
        .catch(() => reject());
    });

    const promiseDemographyByPeriode = new Promise((resolve, reject) => {
      store
        .dispatch("app-decimmo/listDemographyByPeriode", searchYearsGeo)
        .then((response) => {
          reportData.value.demographyByPeriode = response.data;
          resolve();
        })
        .catch(() => reject());
    });

    Promise.all([
      promiseEcosystemPrograms,
      promiseRentEvolution,
      promiseListPrixm2ByPeriode,
      promiseListPrixm2ByType,
      promiseListVentesByPeriode,
      promiseDemographyByPeriode,
    ])
      .then(() => {
        saveReportPositionData(lat, lng);
      })
      .catch(() => {
        saveReportPositionData(lat, lng);
      });
  };

  return {
    // data
    reportData,

    // methods
    fetchReportData,
    fetchReportPositionData,
  };
};
